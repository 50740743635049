import React from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const SufferedFinancialHardship = ({ onselectAnswer, reff, display }) => {

    const optionHandle = async (e) => {
        onselectAnswer(e);
    }

    return (
        <>
            <div style={{ display: display }}>
                <div className="radio-box question animated fadeInUp" ref={reff}>
                    <h3>
                        I have suffered financial hardship due to interest and charges being applied to my account - do you agree?
                    </h3>
                    <RadioButtonQuest
                        key="Agree"
                        className="radio-button"
                        labelName="Agree"
                        labelClassName="option_but next-2"
                        onClick={optionHandle}
                        value="265"
                        name="financialhardship"
                        id="agree98"
                        dataQuestId={98}
                        dataAnsId={265}
                        dataAnsLabel="Agree"
                    />
                    <RadioButtonQuest
                        key="Disagree"
                        className="radio-button"
                        labelName="Disagree"
                        labelClassName="option_but next-2"
                        onClick={optionHandle}
                        value="266"
                        name="financialhardship"
                        id="disagree98"
                        dataQuestId={98}
                        dataAnsId={266}
                        dataAnsLabel="Disagree"
                    />
                </div>
            </div>
        </>
    );
};

export default SufferedFinancialHardship;
