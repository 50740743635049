import { useState, useContext } from "react";
import logowhite from "../../assets/OD_UFL_4_2/img/logo-white.png";
import bnrmob from "../../assets/OD_UFL_4_2/img/bnr-mob.svg";
import icon01 from "../../assets/OD_UFL_4_2/img/icon01.svg";
import icon02 from "../../assets/OD_UFL_4_2/img/icon02.svg";
import icon03 from "../../assets/OD_UFL_4_2/img/icon03.svg";
import findicon from "../../assets/OD_UFL_4_2/img/977009-200.png";
import bannerimg from "../../assets/OD_UFL_4_2/img/bnr-img.svg";
import TrustPilot from "../Includes/Layouts/OD_UFL_4_2/TrustPilot";
import { ODMainLenders, ODMoreLenders } from "../../Constants/Lender";
import { useClaimReg } from "../../Hooks/useClaimReg";
import PersonalDetails from "../Includes/Layouts/OD_UFL_4_2/PersonalDetails";
import { FormProvider } from "react-hook-form";
import { useForm } from "react-hook-form";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";

const FormOD_UFL_4_2 = () => {
  const { updateClaimReg } = useClaimReg();
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const [showPersonalDetails, setShowPersonalDetails] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);

  const [showMoreLenders, setShowMoreLenders] = useState(false);
  const [selectedBank, setSelectedBank] = useState([]);

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();

  const BankSelection = async (e) => {
    console.log(e.target.label);
    const formattedBank = {value: e.target.value, label: e.target.value}
    setSelectedBank([formattedBank]);
    if (e.target.value) {
      const claimReg = await updateClaimReg(
        uuid,
        "OD_UFL_4_2",
        "register_claim"
      );
      if (claimReg.data.status == "Success") {
        GtmDataLayer({
          'question': 'register now'
        });
        setShowPersonalDetails(true);
      }
    }
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <section className="banner">
        <div className="container-fluid caption-top"><p>The Average Refund for Overdraft Fee's (Jan'22 - Sept '24) <strong> £903</strong></p></div>
          <div className="container">
            <div>
              <div className="brand">
                <div className="logo pt-5">
                  <a>
                    <img src={logowhite} height={60} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className="banner-inner">
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className="banner-caption">
                      <div className="overflow-hidden">
                        <h1 className="fw-bold drop-in tex-lg-start text-md-start text-sm-center text-center">
                          Reclaim Unaffordable Overdraft Fees – Start Your Claim
                          Today!
                        </h1>
                      </div>
                      <div className="overflow-hidden">
                        <p className="pt-0 drop-in-2 subtext tex-lg-start text-md-start text-sm-center text-center">
                        Starting your claim is easy and 100% online. You could recover money back if you were mis-sold
                        </p>
                      </div>
                      <div className="text-center man-art-mobile d-lg-none d-md-block d-sm-block d-block">
                        <img src={bnrmob} alt="" />
                      </div>
                      <section className="banks-section text-center mt-4">
                        <div className="container">
                          <fieldset className="styled-fieldset ques1">
                            <legend className="styled-legend">
                              Which bank did you have your overdraft with?
                            </legend>
                            <ul className="bank-list">
                              {ODMainLenders.map((bank) => (
                                <li key={bank.value}>
                                  <label
                                    htmlFor={bank.value}
                                    className="lender-items fade-in "
                                  >
                                    <div className="chooseLender">
                                      <input
                                        type="radio"
                                        id={bank.value}
                                        name="bank"
                                        value={bank.value}
                                        checked={selectedBank === bank.id}
                                        onChange={BankSelection}
                                      />
                                      {bank.label}
                                    </div>
                                  </label>
                                </li>
                              ))}
                            </ul>
                            <div className="text-center mt-3 fade-in mb-1">
                            <button
                              className="btn btn-find-more w-100"
                              id="findMoreBanks"
                              onClick={() => setShowMoreLenders("active")}
                            >
                              <img
                                src={findicon}
                                alt="Search Icon"
                                className="find-icon"
                              />
                              Find more banks
                            </button>
                          </div>
                          </fieldset> 
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 d-lg-block d-md-none d-none">
                    <div className="bannerimage-wraper text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                      <img className="bannerimg fade-in pb-5" src={bannerimg} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className={`more-lender p-3 ${showMoreLenders}`}
          id="moreBanksSection"
        >
          <div className="container home-lenders">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="my-2 title">Other Banks</h3>
              <div id="backlender" onClick={() => setShowMoreLenders("")}>
                <i className="bi bi-chevron-left" /> Back
              </div>
            </div>
            <ul className="lend-list m-0 p-0 mt-3 justify-content-center">
              {ODMoreLenders.map((bank) => (
                <li key={bank.value}>
                  <label htmlFor={bank.value} className="lender-items fade-in">
                    <div className="chooseLender">
                      <input
                        type="radio"
                        value={bank.value}
                        id={bank.value}
                        name="bank"
                        onChange={BankSelection}
                      />

                      {bank.label}
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <TrustPilot />
          {/* End Banner */}
          <section className="welcome-highlights py-lg-5 py-md-0 pb-lg-0 pb-md-5 pb-sm-5 pb-5">
            <div className="container">
              <div className="row pb-5">
                <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                  <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                    <img src={icon01} alt="" />
                    <h2 className="mt-3">
                      Get your Mis-sold Car Finance Fees and Charges Back
                    </h2>
                    <p>
                      Many car finances were not sold properly. You could claim
                      back the fees and charges plus 8% interest.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                  <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                    <img src={icon02} alt="" />
                    <h2 className="mt-3">
                      Your Claim Fought by Financial &amp; Legal Experts
                    </h2>
                    <p>
                      Many loans were not sold properly. You could claim back the
                      fees and charges plus 8% interest.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                  <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                    <img src={icon03} alt="" />
                    <h2 className="mt-3">Respecting Your Information Security</h2>
                    <p>
                      We take security very seriously and follow the rules of the
                      Data Protection Act
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <PersonalDetails
            show={showPersonalDetails}
            selectedBank={selectedBank}
          />
        </div>
      </FormProvider>

    </>
  );
};

export default FormOD_UFL_4_2;
