import React from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const StruggledRepayments = ({ onselectAnswer, reff, display }) => {

    const optionHandle = async (e) => {
        onselectAnswer(e);
    }

    return (
        <>
            <div style={{ display: display }}>
                <div className="radio-box question animated fadeInUp" ref={reff}>
                    <h3>
                        Have you struggled to meet your essential repayments as result of being in your overdraft?
                    </h3>
                    <RadioButtonQuest
                        key="Yes"
                        className="radio-button"
                        labelName="Yes"
                        labelClassName="option_but next-2"
                        onClick={optionHandle}
                        value="286"
                        name="struggledRepayments"
                        id="yes286"
                        dataQuestId={103}
                        dataAnsId={286}
                        dataAnsLabel="Yes"
                    />
                    <RadioButtonQuest
                        key="No"
                        className="radio-button"
                        labelName="No"
                        labelClassName="option_but next-2"
                        onClick={optionHandle}
                        value="287"
                        name="struggledRepayments"
                        id="no287"
                        dataQuestId={103}
                        dataAnsId={287}
                        dataAnsLabel="No"
                    />
                </div>
            </div>
        </>
    );
};

export default StruggledRepayments;
