import React, { useState } from 'react';
import TextField from '../../../UI/TextField';
import SelectBox from '../../../UI/SelectBox';
import { OpeningCreditLimitOption } from '../../../../Constants/Constants';

const OpeningCreditLimit = ({
    validation,
    getValues,
    trigger,
    saveInputAns,
    backClick
}) => {
    const [error, setError] = useState("");

    const handleClick = async(e) => {
       const values = getValues();
       const creditlimit = values["creditLimit"] ;
        let questionrslt = await trigger("creditLimit");
        // const re = /^[1-9]\d*$/;

        if (questionrslt) {
        if (creditlimit == "" || creditlimit == "£0" || creditlimit == "0" || creditlimit == null) {
            setError("Please enter a valid credit limit");
        }else{
            setError("");
            saveInputAns(e);
        }
    }else{
        setError("Please enter credit limit");
    }
    }
    const dontRem = (e) => {
        if(e.target.name == "dontRemember"){               
            saveInputAns(e);
        }
    }

    const handleChange = (e) => {
        const re = /^[1-9]\d*$/;
        if (e.target.value !== "" && !re.test(e.target.value)) {
          e.target.value = e.target.value.replace(/\D/g, "") * 1;
        }
         if (e.target.value !== "") {
            e.target.value =
              e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      };

    return (
        <div className="question-content mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
            <div className="row">
                <div className="col-lg-8 mb-3">
                    {/* <TextField
                        type="tel"
                        className="form-control"
                        name="creditLimit"
                        // placeholder="£"
                        onChange={handleChange}
                        validation={validation({
                            required : true
                        })}
                    /> */}
                    <SelectBox
                        type=""
                        className="form-control"
                        name="creditLimit"
                        OptionValue={OpeningCreditLimitOption}
                        onChange={() => setError("")}
                        myRef={validation({
                            required: true,
                        })}
                    />
                    {error && (
                        <span className="error_msg">{error}</span>
                    )}
                </div>
                
                <div className="col-lg-4 mb-2">
                    <button
                        type="button"
                        name="openingCreditLimit"
                        className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                        onClick={handleClick}
                        data-quest-id={91}
                    >
                        Next  &#8594;
                    </button>
                </div>

                    <div className="col-lg-4 mb-2 m-auto mt-2">
                        <button
                            type="button"
                            className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            id="nextscroll"
                            name="dontRemember"
                            data-quest-id={91}
                            onClick={dontRem}
                        >
                            I Don't Remember
                        </button>
                    </div>

                    <p className="text-center bck-marwid">
                    <button
                      name="offerLoans"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
  
            </div>
        </div>
    )
}

export default OpeningCreditLimit;