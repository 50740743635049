import React from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const StuckinOverdraft = ({ onselectAnswer, reff, display }) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
            I was stuck in my overdraft and didn't see a positive balance
          </h3>
          <RadioButtonQuest
            key="Always290"
            className="radio-buttons"
            labelName="Always"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="290"
            name="stuckinOverdraft"
            id="opt290"
            dataQuestId={105}
            dataAnsId={290}
            dataAnsLabel="Always"
          />
          <RadioButtonQuest
            key="Sometimes291"
            className="radio-button"
            labelName="Sometimes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="291"
            name="stuckinOverdraft"
            id="opt291"
            dataQuestId={105}
            dataAnsId={291}
            dataAnsLabel="Sometimes"
          />
          <RadioButtonQuest
            key="Rarely292"
            className="radio-button"
            labelName="Rarely"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="292"
            name="stuckinOverdraft"
            id="opt292"
            dataQuestId={105}
            dataAnsId={292}
            dataAnsLabel="Rarely"
          />
          <RadioButtonQuest
            key="Never293"
            className="radio-button"
            labelName="Never"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="293"
            name="stuckinOverdraft"
            id="opt293"
            dataQuestId={105}
            dataAnsId={293}
            dataAnsLabel="Never"
          />
        </div>
      </div>
    </>
  );
};

export default StuckinOverdraft;
