import { useContext, useEffect, useRef, useState } from "react";
import useValidDOB from "../../../../Hooks/useValidDOB";
import { SalutationVAQUB4 } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import Input from "./Input";
import PostCode from "./PostCode";
import DateOfBirth from "./DateOfBirth";
import ContactDetails from "./ContactDetails";
import Sign from "./Sign";
import { useFormContext } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import PrevAddressPopup from "./PrevAddressPopup";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useQueryString } from "../../../../Hooks/useQueryString";
import { blacklist } from "../../../../Hooks/blacklist";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import { useSignature } from "../../../../Hooks/useSignature";
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
import { AppConversionPixel } from "../../../../Constants/EnvConstants";

const PersonalDetails = ({ show,selectedBank }) => {
  const history = useHistory();
  const { updateMilestone } = useUpdateMilestone();
  const { saveSignature } = useSignature();
  const { formDataIngestion } = useDataIngestion();
  const { getBlacklist } = blacklist();
  const { validateDateOfBirth } = useValidDOB();
  const { queryString } = useQueryString();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { register, clearErrors, errors, getValues, trigger, setError } =
    useFormContext();
  const [notDobValid, setNotDobValid] = useState(false);
  const [titleQuest, setTitleQuest] = useState(false);
  const [showRegisterPop, setShowRegisterPop] = useState(false);
  const [previousAddressData, setPreviousAddressData] = useState({});
  const [signatureDataURL, setSignatureDataURL] = useState("");
  const [blacklisted, setBlacklisted] = useState([]);
  const [bankErrMsg, setBankErrMsg] = useState("");

  const question_3 = getValues();

  const formRef = useRef(null);
  const form = formRef.current;
  
  const formDatas = getValues();
  const field = "pid";

  useEffect(() => {
    let responsesss = [];
    (async () => {
      const response = await getBlacklist();
      let dataLength = response.data.response.length;
      responsesss = response.data.response;
      if (dataLength > 0) {
        setBlacklisted(response.data.response);
      }
    })();
  }, []);

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    let lstSalutationResult = await trigger("lstSalutation");
    let salutation = question_3.lstSalutation;
    let txtFNameResult;
    let txtLNameResult;
    let lstDobDayResult;
    let lstDobMonthResult;
    let lstDobYearResult;
    let lstPostResult;
    let address1Result;
    let txtPhoneResult;
    let txtEmailResult;
    let dobday = document.getElementById("DobDay").value;
    let dobmonth = document.getElementById("DobMonth").value;
    let dobyear = document.getElementById("DobYear").value;
    let otherlender;
    let input;
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstPostResult = await trigger("txtPostCode");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstPostResult) {
      address1Result = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (address1Result) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("address1").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
      txtPhoneResult = await trigger("txtPhone");
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }
    if (txtPhoneResult) {
      txtEmailResult = await trigger("txtEmail");
    } else {
      document.getElementById("txtPhone").focus();
      return false;
    }
    if (txtEmailResult) {
        const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);
        if (!dobIsValid) {
          setNotDobValid(true);
          document.getElementById("DobDay").focus();
          document.getElementById("DobMonth").focus();
          document.getElementById("DobYear").focus();
          return false;
        } else {
          setNotDobValid(false);
        }
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult ||
      !lstPostResult ||
      !txtPhoneResult ||
      !txtEmailResult 
    ) {
      errorFlag = 1;
    }
    const includeTitles = ["Mrs", "Ms", "Miss", "Dame", "Lady"];
        
    if (errorFlag === 0) {
      if (includeTitles.includes(salutation)) {
        setTitleQuest(true);
      } else {
        setTitleQuest(false);
      }
      return true;
    } else {
      return false;
    }
  };

  const handleClose = () => {
    setShowRegisterPop(false);
  };

  const openPrevPopup = async () => {
    setShowRegisterPop(true);
  };
  const pageChange = () => {
    let blacklistFlag = false;
    blacklisted.map((value, index) => {
      if ("email" == value.type && value.value == formDatas.txtEmail) {
        blacklistFlag = true;
      }

      if ("phone" == value.type && value.value == formDatas.txtPhone) {
        blacklistFlag = true;
      }
    });

    if (blacklistFlag) {
      window.scrollTo(0, 0);
      history.push(
        `/thankyou-bl?uuid=${visitorParameters?.visitor_parameters?.uuid}`
      );
    } else {
      if (queryString != null) {
        if (queryString.indexOf(`&${field}=`) !== -1) {
          if (AppConversionPixel === "true") {
            history.push("/fbpixel?split_name=OD_UFL_4_2");
          }
        }
      }
      window.scrollTo(0, 0);
      history.push(
        `/questionnaireV24?uuid=${visitorParameters.visitor_parameters.uuid}`
      );
    }
  };

  const formSubmit = async () => {
    if (formDatas.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtAddress3 = form["txtAddress3"].value;
      const txtDistrict = form["txtDistrict"].value;

      formDatas.txtDistrict = txtDistrict;
      formDatas.txtAddress3 = txtAddress3;
      formDatas.txtUdprn = txtUdprn;
      formDatas.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formDatas.txtPz_mailsort = txtPz_mailsort;
      formDatas.txtStreet = txtStreet;
    }
    
    formDatas.lender = [selectedBank[0]];
    formDatas.bud_lender = selectedBank[0].value;
    formDatas.lenders = selectedBank[0].value;


    //***previous_address ****/
    formDatas.previous_postcode = previousAddressData.postcode;
    formDatas.previous_address_line1 = previousAddressData.line_1;
    formDatas.previous_address_line2 = previousAddressData.line_2;
    formDatas.previous_address_line3 = previousAddressData.line_3;
    formDatas.previous_address_county = previousAddressData.county;
    formDatas.previous_address_town = previousAddressData.town;
    formDatas.previous_address_country = previousAddressData.country;
    formDatas.previous_district = previousAddressData.district;
    formDatas.previous_address_id = previousAddressData.prevAddressId;

    if (form["addressprev1"].value) {
      formDatas.previous_postcode = form["txtPrevPostCode"].value.toUpperCase();
      formDatas.previous_address_line1 = form["txtHouseNumberPrev"].value;
      formDatas.previous_address_line2 = form["txtAddress3prev"].value;
      formDatas.previous_address_line3 = form["txtAddress3Previous"]?.value;
      formDatas.previous_address_county = form["txtCountyprev"].value;
      formDatas.previous_address_town = form["txtTownprev"].value;
      formDatas.previous_address_country = form["txtCountryprev"].value;
      formDatas.previous_district = form["txtDistrictPrev"].value;
      formDatas.previous_address_id = form["addressprev1"].value;
    }

    formDatas.user_sign = signatureDataURL;
    formDatas.Previousname = form["txtPreName"].value;

    formDatas.page_name = "OD_UFL_4_2";
    formDatas.pCode_manual = form["pCode_EntryType"].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      // let dobMonth = formDatas.DobMonth ? MonthNameArray[formDatas.DobMonth] : '';
      localStorage.setItem("fName", formDatas.txtFName);
      localStorage.setItem("lName", formDatas.txtLName);
      localStorage.setItem("pCode", formDatas.txtPostCode);
      localStorage.setItem("addressLine1", formDatas.txtHouseNumber);
      localStorage.setItem("dobYr", formDatas.DobYear);
      localStorage.setItem("dobMon", formDatas.DobMonth);
      localStorage.setItem("dobDy", formDatas.DobDay);
      localStorage.setItem("addressLine2", formDatas.txtAddress2);
      localStorage.setItem("postTown", formDatas.txtTown);
      localStorage.setItem("country", formDatas.txtCounty);
      localStorage.setItem("title", formDatas.lstSalutation);
      localStorage.setItem("addressLine3", formDatas.txtAddress3);
      localStorage.setItem("county", formDatas.txtCounty);
      localStorage.setItem(
        "district",
        formDatas.txtDistrict ? formDatas.txtDistrict : ""
      );
      let UcFname =
        formDatas.txtFName.charAt(0).toUpperCase() +
        formDatas.txtFName.slice(1);

      localStorage.setItem("UcFname", UcFname);

      localStorage.setItem("formData", JSON.stringify(formDatas));
      localStorage.setItem("queryString", queryString);

      if (signatureDataURL) {
        const formSUbmitResult = formDataIngestion(
          visitorParameters.visitor_parameters,
          formDatas,
          "split_form_submit",
          "OD_UFL_4_2",
          queryString,
          visitorParameters.data
        );
        const message_type = "signature_store";
        const response = await saveSignature(
          signatureDataURL,
          visitorParameters.visitor_parameters,
          formDatas,
          visitorParameters.data,
          queryString,
          message_type
        );

        if (response.data.status === "Success") {
          updateMilestone(
            visitorParameters.visitor_parameters.uuid,
            "lead",
            "live"
          );
          updateMilestone(
            visitorParameters.visitor_parameters.uuid,
            "signature",
            "live"
          );
          pageChange();
        }
      }
    } else {
      return     "Fail";
    }
  };

  return (
    <>
      <form ref={formRef} className="form-font">
        <div className={`sidesection py-2 pt-0 ${show ? "is-open" : ""} `}>
          <div>
            <div className="form-style form-body p-4 pb-5">
              <h2 className="mb-3  title">Register Your Claim Online Now</h2>
              <div className="row">
                <div className="col-lg-2 mb-2">
                  <SelectBox
                    className="form-select select1 select-style"
                    OptionValue={SalutationVAQUB4}
                    name="lstSalutation"
                    id="lstSalutation"
                    onChange={(e) => {
                      clearErrors("lstSalutation");
                    }}
                    clearErrors={clearErrors}
                    myRef={register({ required: "Please select your title" })}
                    validationMsg={
                      errors.lstSalutation && errors.lstSalutation.message
                    }
                  />
                </div>
                <div className="col-lg-5 ps-lg-0">
                  <div className="mb-2">
                    <Input
                      type="text"
                      placeholder="First Name"
                      className="form-control input-style"
                      name="txtFName"
                      id="txtFName"
                      rules={{
                        required: "Please enter your first name",
                        minLength: {
                          value: 3,
                          message: "Please enter valid first name",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "Your name is invalid. Please recheck",
                        },
                      }}
                      transform={(value) =>
                        value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                      }
                    />
                    {errors?.txtFName && (
                      <ErrorMessage errMessage={errors?.txtFName?.message} />
                    )}
                  </div>
                </div>
                <div className="col-lg-5 ps-lg-0">
                  <div className="mb-2">
                    <Input
                      type="text"
                      placeholder="Last Name"
                      className="form-control input-style"
                      name="txtLName"
                      id="txtLName"
                      rules={{
                        required: "Please enter your first name",
                        minLength: {
                          value: 3,
                          message: "Please enter valid first name",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "Your name is invalid. Please recheck",
                        },
                      }}
                      transform={(value) =>
                        value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                      }
                    />
                    {errors?.txtLName && (
                      <ErrorMessage errMessage={errors?.txtLName?.message} />
                    )}
                  </div>
                </div>

                <PostCode />
              </div>

              <DateOfBirth notDobValid={notDobValid} />

              <ContactDetails />

              <Sign
                setSignatureDataURL={setSignatureDataURL}
                basicDetailsValidation={basicDetailsValidation}
                openPrevPopup={openPrevPopup}
              />

              <PrevAddressPopup
                show={showRegisterPop}
                titleQuest={titleQuest}
                handleClose={handleClose}
                formSubmit={formSubmit}
                setPreviousAddressData={setPreviousAddressData}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PersonalDetails;
