import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/VAQ_ID_UPLOAD/css/Vanquis_id_upload.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { userInfo } from "../../Hooks/userInfo";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { useLogRecord } from "../../Hooks/UseLogRecord";
import { Api } from "../../api/Api";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const VerifyData_VAQ_4 = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const { updateLogRecord } = useLogRecord();
  const { getUserVerfyValidation, saveUserVerify } = useUserVerify();
  const { UserDataInfo, VaQPdfGeneration } = userInfo();
  const {updateMilestone} = useUpdateMilestone();
  const splitName =localStorage.getItem("split_name");
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");

  const query_string = localStorage.getItem("queryString");
  let useruuid;
  let fName;
  let lName;
  let pCode;
  let addressLine1;
  let dobYr;
  let dobDy;
  let addressLine2;
  let postTown;
  let country;
  let title;
  let addressLine3;
  let addressLine4;
  let county;
  let dobMon;
  let email;

  useruuid = localStorage.getItem("uuid");

  const getCurrentDateTime = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = new URLSearchParams(window.location.search);
        const uuid = query.get("uuid");

      //   const responsepdf =  Api.get(
      //     "/api/v1/vaq-pdf-generate?uuid=" + uuid,
      //     {}
      // );

        const formdata = JSON.parse(localStorage.getItem("formData"));
        if (formdata === undefined || formdata === null || formdata == "") {
        
          (async () => {
            const response = await UserDataInfo({ uuid: uuid });
            const res = response.data.response;

            title = res.title;
            fName = res.first_name;
            lName = res.last_name;
            pCode = res.postcode;
            addressLine1 = res.address_line1;
            dobYr = res.dob_yr;
            dobMon = res.dob_month;
            dobDy = res.dob_day;
            addressLine2 = res.address_line2;
            addressLine3 = res.address_line3;
            addressLine4 = res.address_Line4;
            postTown = res.town;
            country = res.country;
            county = res.county;
            useruuid = res.uuid;
            email = res.email;

            setTimeout(() => {
              veripyCall();
            }, 4000);
          })();
        } else {
          const lstSalutation = formdata ? formdata.lstSalutation : "";
          const addr1 = formdata ? formdata.txtHouseNumber : "";
          const addr2 = formdata ? formdata.txtAddress2 : "";
          const addr3 = formdata ? formdata.txtAddress3 : "";
          const dob_date = formdata ? formdata.DobDay : "";
          const dob_month = formdata ? formdata.DobMonth : "";
          const dob_year = formdata ? formdata.DobYear : "";
          const userCity = formdata ? formdata.txtTown : "";
          const userCounty = formdata ? formdata.txtCounty : "";
          const userCountry = formdata ? formdata.txtCountry : "";
          const ph = formdata ? formdata.txtPhone : "";
          const post = formdata ? formdata.txtPostCode.toUpperCase() : "";
          const firstName = formdata ? formdata.txtFName : "";
          const lastName = formdata ? formdata.txtLName : "";
          const addressLine_4 = formdata ? formdata?.addressLine4 : "";
          title = lstSalutation;
          fName = firstName;
          lName = lastName;
          pCode = post;
          addressLine1 = addr1;
          addressLine2 = addr2;
          addressLine3 = addr3;
          addressLine4 = addressLine_4;
          postTown = userCity;
          country = userCountry;
          county = userCounty;
          dobYr = dob_year;
          dobMon = dob_month;
          dobDy = dob_date;
          email = formdata ? formdata?.txtEmail : "";

          veripyCall();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const veripyCall = () => {
    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      dobYr &&
      dobMon &&
      dobDy
    ) {
      const startTime = performance.now();
      let startDateTime = getCurrentDateTime();
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYr,
          dobMon,
          dobDy,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county
        );
        let endDateTime = getCurrentDateTime();

        const endTime = performance.now();

        const Timems = endTime - startTime;

        const timeTaken = (Timems / 1000).toFixed(2); // Convert milliseconds to seconds

        if (response.data.status == "success") {
          localStorage.setItem("t2aValidation", 1);
          updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",1);
        } else {
          localStorage.setItem("t2aValidation", 0);
          updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",2);
        }
        await storeT2aResponse(response.data);
        const LogRecord = await updateLogRecord(
          useruuid,
          "Person_Search_VAQ_UB_4",
          timeTaken,
          startDateTime,
          endDateTime
        );
      })();
    }
  };

  const storeT2aResponse = async (t2a_data) => {
    try {
      const response = await saveUserVerify(
        visitorParameters.visitor_parameters,
        t2a_data,
        "person_verify_store",
        visitorParameters.data,
        query_string,
        {
          useruuid: useruuid,
          fName: fName,
          lName: lName,
          pCode: pCode,
          addressLine1: addressLine1,
          dobYr: dobYr,
          dobDy: dobDy,
          addressLine2: addressLine2,
          postTown: postTown,
          country: country,
          title: title,
          addressLine3: addressLine3,
          addressLine4: addressLine4,
          county: county,
          dobMon: dobMon,
        }
      );
      const t2a_Validation = localStorage.getItem("t2aValidation");
      const split_name = localStorage.getItem("split_name");

    if(split_name === "VAQ_UB_4_3_1" || split_name === "VAQ_UB_4_3_2" || split_name === "VAQ_UB_4_3_1_B" ){
      window.scrollTo(0, 0);
      history.push(
        "/PreviewVAQ_UB_4_3_1?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    } else if(split_name === "VAQ_UB_4_3_1_C" || split_name === "VAQ_UB_4_3_1_D" || split_name === "VAQ_UB_4_3_1_C2") {
      window.scrollTo(0, 0);
      history.push(
        "/PreviewVAQ_UB_4_3_C?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    } else if(split_name === "VAQ_UB_5") {
      window.scrollTo(0, 0);
      history.push(
        "/PreviewQuestVaq_V5?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    } else if(split_name === "OD_UFL_4" || split_name === "OD_UFL_4_2") {
      window.scrollTo(0, 0);
      history.push(
         "/PreviewODUFL4?uuid=" + visitorParameters.visitor_parameters.uuid
        );
    } else {
      window.scrollTo(0, 0);
      history.push(
        "/PreviewVAQ4?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    }
    } catch (error) {
      console.error("Error storing T2a response:", error);
    }
  };

  return (
    <>
      <GetVisitorsParams />

      <div className="Vaq_id_upload">
        <header>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-12 col-md-12 col-lg-12 logo text-center">
                <img src="assets/VAQ_ID_UPLOAD/img/logo-white.png" alt="" />
              </div>
            </div>
          </div>
        </header>
        <section className="content">
          <div className="container text-center">
            <div className={`loader-wrap text-center text-light`}>
              <div>
                <img
                  height={80}
                  src="assets/VAQ_ID_UPLOAD/img/loader-review2.svg"
                  alt=""
                />
                <h6 className="mt-3">Analysing your answers</h6>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default VerifyData_VAQ_4;
