import React from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const ReceiveIncome = ({ onselectAnswer, reff, display }) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
            In the year when you took the overdraft did you receive any income?
          </h3>
          <RadioButtonQuest
            key="Yes"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="267"
            name="recieveanyincome"
            id="inyes77"
            dataQuestId={100}
            dataAnsId={267}
            dataAnsLabel="Yes"
          />
          <RadioButtonQuest
            key="No"
            className="radio-button"
            labelName="No"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="268"
            name="recieveanyincome"
            id="inno77"
            dataQuestId={100}
            dataAnsId={268}
            dataAnsLabel="No"
          />
        </div>
      </div>
    </>
  );
};

export default ReceiveIncome;

