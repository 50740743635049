import { useState } from "react";
import logowhite from "../../../../assets/OD_UFL_4_2/img/logo-white.png"
import Complaints from "../Common/Complaints";
import PrivacyPolicy from "../Common/PrivacyPolicy";
import TC from "../../../../assets/pdf/TOBA_New.pdf";
const Footer = () => {

  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showterms, setShowterms] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const modalClick = (clickValue) => {
    switch (clickValue) {
      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showTerms": {
        setShowterms(true);
        break;
      }
      case "exitTerms": {
        setShowterms(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
      default: {
        console.log("No such case exist");
        break;
      }
    }
  };
  return (
    <>
      <footer className="py-lg-5 py-md-5 py-sm-4 py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 tex-lg-start mb-3 text-lg-start text-md-start text-sm-center text-center">
              <div className="ft-logo">
                {" "}
                <img src={logowhite} height={70} alt="" />
              </div>
            </div>
            <div className="col-lg-9">
              <p>
                TMS Legal Ltd is a limited company registered in England and
                Wales under company no: 10315962 Its registered office is Arena,
                9 Nimrod Way, Ferndown, BH21 7UH It is authorised and regulated
                by the Solicitors Regulation Authority, Number: 645041
              </p>
              <PrivacyPolicy showPrivacy={showPrivacy} modalClick={modalClick} />

              <Complaints showComplaint={showComplaint} modalClick={modalClick} />
                <ul className="m-0 p-0  ">
                  <li>
                    <a onClick={() => modalClick("showPrivacy")}
                    >
                      Privacy and Cookie Policy
                    </a>
                  </li>
                  <li>
                    <a href={`${TC}`} target="_blank">
                      TMS Legal Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a onClick={() => modalClick("showComplaint")}
                    >
                      Complaints Procedure
                    </a>
                  </li>
                </ul>
              <ul className="m-0 p-0 pt-2 ftr_links">
                <li>
                  <a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf">
                    www.fca.org.uk
                  </a>
                </li>
                <li>
                  <a href="https://www.standard.co.uk/business/money/uk-finance-watchdog-extends-probe-into-car-finance-misselling-b1173692.html">
                    www.standard.co.uk
                  </a>
                </li>
                <li>
                  <a href="https://www.thesun.co.uk/money/30635528/car-finance-misselling-scandal-money-fca/">
                    www.thesun.co.uk
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
