import React, { useState } from 'react';
import CheckboxOutLabel from '../../../../UI/CheckboxOutLabel';

const FurtherBorrowing = ({
    reff,
    display,
    onselectAnswer,
    checkedOptions,
    setCheckedOptions,
}) => {

    const [questResult, setQuestResult] = useState(false);

    const labelclicked = (e) => {
        e.preventDefault();
        let selectedCount = checkedOptions.length;
        if (selectedCount == 0) {
            setQuestResult(true);
        } else {
            setCheckedOptions(checkedOptions);
            onselectAnswer(e);
        }
    };

    const onSelectOption = (e) => {
        if (e.target.checked === true) {
            setQuestResult(false);
            checkedOptions.push({ id: +e.target.value });
        } else {
            let deselect = e.target.value;
            const decre = checkedOptions.map((OptionArray, index) => {
                if (deselect == OptionArray.id) {
                    checkedOptions.splice(index, 1);
                    if (checkedOptions.length == 0) {
                    }
                }
            });
        }
    };

    return (
        <>
            <div style={{ 'display': display }}>
                <div className="radio-box question animated fadeInUp" ref={reff} >
                    <h4 style={{ color: '#69a2d7' }} className='text-center'><b>
                        Did you take out further borrowing so that you could manage your finances? Yes
                    </b></h4>
                    <ul className="options secondop">
                        <li >
                            <CheckboxOutLabel
                                name="furtherborrowingforfinance"
                                id="optionztwo282"
                                value="282"
                                className="custom-checkbox"
                                labelName="Payday lending"
                                onClick={onSelectOption}
                                dataQuestId={102}
                                dataAnsId={282}
                                dataAnsLabel="Payday lending"
                            />
                        </li>
                        <li>
                            <CheckboxOutLabel
                                name="furtherborrowingforfinance"
                                id="optionztwo2283"
                                value="283"
                                className="custom-checkbox"
                                labelName="Loans"
                                onClick={onSelectOption}
                                dataQuestId={102}
                                dataAnsId={283}
                                dataAnsLabel="Loans"
                            />
                        </li>
                        <li>
                            <CheckboxOutLabel
                                name="furtherborrowingforfinance"
                                id="optionztwo3284"
                                value="284"
                                className="custom-checkbox"
                                labelName="Credit cards"
                                onClick={onSelectOption}
                                dataQuestId={102}
                                dataAnsId={284}
                                dataAnsLabel="Credit cards"
                            />
                        </li>
                        <li>
                            <CheckboxOutLabel
                                name="furtherborrowingforfinance"
                                id="optionztwo4285"
                                value="285"
                                className="custom-checkbox"
                                labelName="Borrowing from friends and family"
                                onClick={onSelectOption}
                                dataQuestId={102}
                                dataAnsId={285}
                                dataAnsLabel="Borrowing from friends and family"
                            />
                        </li>
                    </ul>

                    <div className="col-xl-12 col-lg-12 text-center p-0" >
                        {questResult && (
                            <span className="error_msg">Please select at least one option</span>
                        )}
                        <button
                            type="button"
                            className="btn-next"
                            onClick={labelclicked}
                            data-quest-id={102}
                            data-ans-id={null}
                            data-ans-label={null}
                            name="furtherBorrow"
                        >{`Next`}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FurtherBorrowing;