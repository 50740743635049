import React from "react";
import { DependentODFLP } from "../../../../../Constants/Constants";
import SelectBox from "../../../../UI/SelectBox";
const Dependents = ({
    onselectAnswer,
    validationMsg,
    clearErrors,
    validation,
    trigger,
    reff,
}) => {
    const optionSelected = async (e) => {
        let errorFlag = 0;
        let result = await trigger("dependents");

        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            onselectAnswer(e);
        }
    };
    return (
        <>
            <div className={`radio-box animated fadeInUp`} ref={reff}>
                <h3>
                    <b>
                        How many dependents did you have at that time?
                    </b>
                </h3>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                    <SelectBox
                        className="form-select form-control"
                        OptionValue={DependentODFLP}
                        name="dependents"
                        id="dependents"
                        onChange={() => clearErrors("dependents")}
                        myRef={validation({ required: "Please select your dependent" })}
                        validationMsg={
                            validationMsg.dependents && validationMsg.dependents.message
                        }
                    ></SelectBox>
                </div>
                <div className="col-xl-12 col-lg-12 text-center p-0">
                    <button
                        type="button"
                        className="btn-next"
                        onClick={optionSelected}
                        data-quest-id={3}
                        data-ans-id={null}
                        data-ans-label={null}
                        name="dependents"
                    >{`NEXT`}</button>
                </div>
            </div>
        </>
    );
};
export default Dependents;
