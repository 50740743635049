import React, { useState } from "react";
import { HighestCreditLimitOption } from "../../../../../Constants/Constants";
import SelectBox from "../../../../UI/SelectBox";

const HighestCreditLimit = ({
  validation,
  getValues,
  reff,
  display,
  saveInputAns,
  trigger,
}) => {
  const [error, setError] = useState("");

  const handleClick = async (e) => {
    let highcreditLimit = await trigger("highcreditLimit");
    let errorFlag = 0;
    if (!highcreditLimit) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      setError("");
      saveInputAns(e);
    } else {
      setError("Please enter credit limit");
      return false;
    }
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>What is the highest credit limit you had on your overdraft?</h3>
            <SelectBox
              type=""
              className="form-control"
              name="highcreditLimit"
              OptionValue={HighestCreditLimitOption}
              onChange={() => setError("")}
              myRef={validation({
                required: true,
              })}
            />
            {error && <span className="error_msg mt-1">{error}</span>}
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={handleClick}
            data-quest-id={94}
            data-ans-id={null}
            data-ans-label={null}
            name="highestCreditLimit"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default HighestCreditLimit;
