import React, { useState } from 'react';
import TextField from '../../../UI/TextField';
import SelectBox from '../../../UI/SelectBox';

const HighestNum = ({
    validation,
    getValues,
    trigger,
    saveInputAns,
    backClick
}) => {
    const [error, setError] = useState("");

    const handleClick = async (e) => {
        const values = getValues();
        const highestNumber = values["highestNumber"];
        let questionrslt = await trigger("highestNumber");
        // const re = /^[1-9]\d*$/;

        if (questionrslt) {
            if (highestNumber == "" || highestNumber == "£0" || highestNumber == "0" || highestNumber == null) {
                setError("Please enter a valid credit limit");
            } else {
                setError("");
                saveInputAns(e);
            }
        } else {
            setError("Please enter credit limit");
        }
    }

    const handleChange = (e) => {
        const re = /^[1-9]\d*$/;
        if (e.target.value !== "" && !re.test(e.target.value)) {
            e.target.value = e.target.value.replace(/\D/g, "") * 1;
        }
        if (e.target.value !== "") {
            e.target.value =
                e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    };

    return (
        <div className="question-content mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
            <div className="row">
                <div className="col-lg-8 mb-3">
                    <div className="odflcredlimit">
                        <TextField
                            type="tel"
                            className="form-control"
                            name="highestNumber"
                            // placeholder="£"
                            onChange={handleChange}
                            validation={validation({
                                required: true
                            })}
                        />
                        {error && (
                            <span className="error_msg">{error}</span>
                        )}
                    </div>
                </div>

                <div className="col-lg-4 mb-2">
                    <button
                        type="button"
                        name="highestNum"
                        className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                        onClick={handleClick}
                        data-quest-id={113}
                    >
                        Next  &#8594;
                    </button>
                </div>
                <p className="text-center bck-marwid">
                    <button
                        name="applythisFollowing"
                        className="btn btn-secondary backbtn1a"
                        id=""
                        value=""
                        type="button"
                        onClick={backClick}
                    >Go Back</button>
                </p>

            </div>
        </div>
    )
}

export default HighestNum;