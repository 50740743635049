import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import InputButton from '../../../UI/InputButton';

const OfferLoans = ({ onSelectAnswer, backClick,highestNumBck }) => {
    let toggle="";
    if(highestNumBck.some(option => option.id === 308)){
        toggle = "highestNum";
    } else {
        toggle = "applythisFollowing";
    }

    const optionHandle = async (e) => {
        onSelectAnswer(e);
    }

    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
                <div className="row">
                    <div className="col-lg-4 mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn`}
                            onClick={optionHandle}
                            value="309"
                            name="offerLoans"
                            id="nextscroll"
                            data-quest-id={110}
                            data-ans-id={309}
                            data-ans-label="Yes, and I accepted"
                        >
                           Yes, and I accepted
                        </button>

                    </div>
                    <div className="col-lg-4 mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn`}
                            onClick={optionHandle}
                            value="310"
                            name="offerLoans"
                            id="nextscroll"
                            data-quest-id={110}
                            data-ans-id={310}
                            data-ans-label="Yes, and I rejected"
                        >
                            Yes, and I rejected
                        </button>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn`}
                            onClick={optionHandle}
                            value="311"
                            name="offerLoans"
                            id="nextscroll"
                            data-quest-id={110}
                            data-ans-id={311}
                            data-ans-label="No, they did not"
                        >
                            No, they did not
                        </button>
                    </div>

                </div>
                <p className="text-center bck-marwid">
                    <button
                      name={toggle}
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>

            </div>
        </>
    );
};

export default OfferLoans;
