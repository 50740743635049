import logoWhite from "../../assets/OD_UFL_4/img/logo-white.png";
import trustPilot from "../../assets/OD_UFL_4/img/trustpilot.png";
import bannerImg from "../../assets/OD_UFL_4/img/banner-img.png";
import sslImg from "../../assets/OD_UFL_4/img/ssl.png";
import IconOne from "../../assets/OD_UFL_4/img/icon01.svg";
import IconTwo from "../../assets/OD_UFL_4/img/icon02.svg";
import IconThree from "../../assets/OD_UFL_4/img/icon03.svg";
import PersonalDetails from "../Includes/Layouts/OD_UFL_4/PersonalDetails";
import { FormProvider } from "react-hook-form";
import { useForm } from "react-hook-form";
import {useState, useContext} from "react";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useClaimReg } from "../../Hooks/useClaimReg";
import VisitorParamsContext from "../../Contexts/VisitorParams";

const FormOD_UFL_4 = () => {
  const { updateClaimReg } = useClaimReg();

  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const [showPersonalDetails, setShowPersonalDetails] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();
  
  const slideChange = async () => {
    const claimReg = await updateClaimReg(
      uuid,
      "OD_UFL_4",
      "register_claim"
    );
    if (claimReg.data.status == "Success") {
      GtmDataLayer({
        'question': 'register now'
      });
      setShowPersonalDetails(true);    
    
  }
  };
  return (
    <>
      <FormProvider {...formMethods}>
        <section className="banner form-font">
          <div className="container">
            <div>
              <div className="brand">
                <div className="logo pt-3">
                  <a href="index.html">
                    <img src={logoWhite} height={60} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="container-xxl">
              <div className="banner-inner">
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12  ">
                    <div className="banner-caption">
                      <div className="overflow-hidden">
                        <h1 className="fw-bold  drop-in text-lg-start text-md-center text-sm-center text-center">
                          Reclaim Unaffordable Overdraft Fees – Start Your Claim
                          Today!
                        </h1>
                      </div>
                      <div className="text-lg-start text-md-center text-sm-center text-center">
                        <div
                          className="hilighttext-homepage drop-in p-2 bg-dark mb-4 d-inline-block mt-2 rounded-1"
                          style={{ "--bs-bg-opacity": ".2" }}
                        >
                          <p className="drop-in pb-0 m-0 text-start">
                            Our Highest Refund is - £3,024 <br />
                            Our Average Refund is - £993 <br/>
                            <p className="text-center m-0">Jan 2022-Sept 2024</p>
                          </p>
                        </div>
                      </div>
                      <div className="overflow-hidden">
                        <p className="  pt-0 drop-in-2 subtext text-lg-start text-md-center text-sm-center text-center">
                          If you received an overdraft that you had difficulty repaying within a reasonable period of
                          time, or you were mainly living in your overdraft and using it for day-to-day expenditure, you
                          may be able to reclaim the interest and fees that were charged to you.
                        </p>
                      </div>
                      <div className=" overflow-hidden tex-lg-start text-md-start text-sm-center text-center mt-lg-3 mt-md-3 mt-sm-0 mt-3">
                        <fieldset className="d-none legent-car p-3 drop-in-3">
                          <legend className="mb-0 ">
                            Enter Your Car Registration Number To
                          </legend>
                          <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-12 pe-lg-1 pe-md-1">
                              <input
                                className="car-number form-control    drop-in-3 h-100"
                                type="text"
                                placeholder="Enter your car registration "
                              />
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-12 ps-lg-1 ps-md-1 mt-lg-0 mt-md-0 mt-sm-2 mt-3">
                              <button
                                className="btn-comm2  drop-in-3 w-100 fw-bold"
                                id=" "
                              >
                                <span>
                                  Regist
                                  <i className="bi bi-caret-right-fill" />
                                </span>
                              </button>
                            </div>
                          </div>
                        </fieldset>
                        <div className="row">
                          <div className="col-lg-7">
                            <button
                              className="btn-comm2 register-claim w-100 drop-in-3  fs-5 scale-anim"
                              id="claimNow"
                              type="button"
                              onClick={slideChange}
                            >
                              <span>
                                Yes, Register My Claim 
                                <i className="bi bi-caret-right-fill" />
                              </span>
                            </button>
                          </div>
                          <div className="col-lg-5 drop-in-3">
                            <div className="mt-3 secure">
                              <img height={35} src={sslImg} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-block d-lg-none d-md-block d-sm-block py-5 ">
                        <div className="banner-car   text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                          <div className="review drop-in-3  d-none">
                            <div className="overflow-hidden ">
                              <img
                                src={trustPilot}
                                className="drop-in-3 "
                                alt=""
                              />
                            </div>
                          </div>
                          <img
                            className=" car-img fade-in"
                            src={bannerImg}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12  d-lg-block d-md-none d-none">
                    <div className="bannerimage-wraper text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                      <div className="review drop-in-3 d-none">
                        <div className="overflow-hidden ">
                          <img src={trustPilot} className="drop-in-3 " alt="" />
                        </div>
                      </div>
                      <img
                        className="bannerimg fade-in"
                        src={bannerImg}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Banner */}
        <section className="welcome-highlights py-lg-5 py-md-0 pb-lg-4 pb-md-5 pb-sm-5 pb-5 form-font">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                  <img src={IconOne} alt="" />
                  <h2 className="mt-3">
                    Get your Mis-sold Overdraft Fees and Charges back
                  </h2>
                  <p>
                    The lender needed to make sure you were able to repay your overdraft within a reasonable
                    period. Therefore, it might not have been fair or reasonable to continue charging interest and
                    charges over the long term.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                  <img src={IconTwo} alt="" />
                  <h2 className="mt-3">
                    Your Claim Fought by Financial &amp; Legal Experts
                  </h2>
                  <p>
                    We're ahead of the field, drawing on years of financial
                    services and legal expertise for a strong claim
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                  <img src={IconThree} alt="" />
                  <h2 className="mt-3">Respecting Your Information Security</h2>
                  <p>
                  We take security very seriously and follow the rules of the Data Protection Act.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
          <PersonalDetails
            show={showPersonalDetails}
          />
      </FormProvider>
    </>
  );
};

export default FormOD_UFL_4;
