import React, { useState } from "react";
import TextField from "../../../../UI/TextField";

const OpeningCreditLimit = ({
  validation,
  getValues,
  reff,
  display,
  saveInputAns,
  trigger,
}) => {
  const [error, setError] = useState("");

  const handleClick = async (e) => {
    const values = getValues();
    const creditlimit = values["opncreditLimit"];
    let questionrslt = await trigger("opncreditLimit");
    if (questionrslt) {
      if (
        creditlimit == "" ||
        creditlimit == "£0" ||
        creditlimit == "0" ||
        creditlimit == null
      ) {
        setError("Please enter a valid credit limit");
      } else {
        setError("");
        saveInputAns(e);
      }
    } else {
      setError("Please enter credit limit");
    }
  };

  const dontRem = async (e) => {
      saveInputAns(e);
  };

  const handleChange = (e) => {
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value = e.target.value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp mb-2" ref={reff}>
          <h3>What was your overdraft opening credit limit?</h3>
          <div className="odflcredlimit">
            <TextField
              type="tel"
              className="form-control"
              name="opncreditLimit"
              onChange={handleChange}
              validation={validation({
                required: true,
              })}
            />
            {error && <span className="error_msg mt-1">{error}</span>}
          </div>
        </div>
        <div className="col-xl-12 col-lg-12 text-center">
          <button
            type="button"
            className="btn-next mb-2"
            onClick={handleClick}
            data-quest-id={91}
            data-ans-id={null}
            data-ans-label={null}
            name="openingCreditLimit"
          >
            Next
          </button>
          <button
            type="button"
            className="btn-next"
            onClick={dontRem}
            data-quest-id={91}
            data-ans-id={null}
            data-ans-label={null}
            name="dontRemember"
          >
            I Don't Remember
          </button>
        </div>
      </div>
    </>
  );
};

export default OpeningCreditLimit;
