import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import Modal from "react-bootstrap/Modal";
import InputButton from "../../../../UI/InputButton";

const BankActedIrresponsibly = ({ onselectAnswer, reff, display, unQualifiedAnswer
}) => {
  const [qualifyModal, setQualifyModal] = useState(false);

  const optionHandle = async (e) => {
    if (e.target.value == 250) {
      unQualifiedAnswer(e);
      setQualifyModal(true);
    } else {
      onselectAnswer(e);
    }
  };

  const handleCloseclaimModal = (e) => {
    setQualifyModal(false);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
            The bank acted irresponsibly by increasing my overdraft limit
            without conducting a proper affordability assessment - do you agree?
          </h3>
          <RadioButtonQuest
            key="Agree"
            className="radio-button"
            labelName="Agree"
            labelClassName="option_but next-2"
            onClick={optionHandle}
            value="249"
            name="bankActedIrresponsibly"
            id="agree"
            dataQuestId={93}
            dataAnsId={249}
            dataAnsLabel="Agree"
          />
          <RadioButtonQuest
            key="Disagree"
            className="radio-button"
            labelName="Disagree"
            labelClassName="option_but next-2"
            onClick={optionHandle}
            value="250"
            name="bankActedIrresponsibly"
            id="disagree"
            dataQuestId={93}
            dataAnsId={250}
            dataAnsLabel="Disagree"
          />
        </div>
        <Modal
          size="md"
          show={qualifyModal}
          onHide={handleCloseclaimModal}
          centered
          backdrop="static"
          className="claimdeinial"
        >
          <Modal.Body>
            <div className="text-center my-3">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9Z"
                  fill="#EF8019"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.5 9C15.2239 9 15 8.77614 15 8.5C15 8.22386 15.2239 8 15.5 8C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9Z"
                  fill="#EF8019"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 15.5C7.5 15.5 9 13.5 12 13.5C15 13.5 16.5 15.5 16.5 15.5"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h3 className="text-center">You don't qualify.</h3>
            <div className="input-group mb-3 prevname-Btnpop justify-content-center">
              <InputButton
                className="btn next08  regNextBtnNew text-center"
                name="modalBack"
                value="Back"
                btnType="button"
                onClick={handleCloseclaimModal}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default BankActedIrresponsibly;
