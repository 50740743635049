import React from "react";
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const TrustPilot = () => {
  return (
    <>
      <section className="welcome-highlights pb-lg-2 pb-md-3 pb-sm-3 pb-5 ">
        <div className="container pt-2">
          <div className="reviews-block text-white rounded p-3">
            <Carousel id="carouselExampleIndicators" className="carousel slide">
              {/* <div className="carousel-inner"> */}
                <Carousel.Item className="carousel-item">
                  <a
                    href="https://www.trustpilot.com/review/pba-claims.co.uk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="mb-3">
                      <img
                        src="https://dev.themoneysolicitor.com/static/media/stars-5.27f94caa.svg"
                        alt="Trustpilot star rating"
                        style={{ height: 24 }}
                      />
                    </div>
                    <h5>Great review from me contact was very good</h5>
                    <p>
                      Great review from me contact was very good, always replied
                      when messaged, quick response too.
                      <br />A long process but not in any way TMC's fault. Can’t
                      fault, would recommend. For me, a nice payout too thank
                      you, that’s helped towards my wedding. Brilliant, thanks
                      again.
                    </p>
                    <p>
                      <b>Paul Hammers Jackson</b>
                    </p>
                    <p>
                      <b>Date of experience:</b> September 03, 2024
                    </p>
                  </a>
                </Carousel.Item>
                <Carousel.Item className="carousel-item ">
                  <a
                    href="https://www.trustpilot.com/review/pba-claims.co.uk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="mb-3">
                      <img
                        src="https://dev.themoneysolicitor.com/static/media/stars-5.27f94caa.svg"
                        alt="Trustpilot star rating"
                        style={{ height: 24 }}
                      />
                    </div>
                    <h5> Excellent experience</h5>
                    <p>
                      {" "}
                      Excellent experience. They did everything for me. Within a
                      week of winning the claim, the money was in my account. It
                      does take time but it’s worth it. Don’t worry if they
                      don’t contact you for a few months; they are going through
                      the process. I started to worry with no contact, but I
                      emailed them and they responded within a day or two.
                    </p>
                    <p>
                      {" "}
                      <b>Dean</b>
                    </p>
                    <p>
                      {" "}
                      <b>Date of experience: September 05, 2024</b>{" "}
                    </p>
                  </a>
                </Carousel.Item>
                <Carousel.Item className="carousel-item">
                  <a href target="_blank" rel="noopener noreferrer">
                    <div className="mb-3">
                      <img
                        src="https://dev.themoneysolicitor.com/static/media/stars-5.27f94caa.svg"
                        alt="Trustpilot star rating"
                        style={{ height: 24 }}
                      />
                    </div>
                    <h5> I got back almost £1,500</h5>
                    <p>
                      {" "}
                      I'm stunned they got back almost £1,500 for me after I saw
                      their ad on Facebook. Yes, their commission is a hefty
                      45%, but I didn't expect to get any money back, so I am
                      not complaining.
                    </p>
                    <p>
                      {" "}
                      <b>Lorraine King</b>
                    </p>
                    <p>
                      {" "}
                      <b>Date of experience: September 13, 2024</b>{" "}
                    </p>
                  </a>
                </Carousel.Item>
                <Carousel.Item className="carousel-item">
                  <a href target="_blank" rel="noopener noreferrer">
                    <div className="mb-3">
                      <img
                        src="https://dev.themoneysolicitor.com/static/media/stars-5.27f94caa.svg"
                        alt="Trustpilot star rating"
                        style={{ height: 24 }}
                      />
                    </div>
                    <h5> The service from TMS was second to none</h5>
                    <p>
                      {" "}
                      The service from TMS was second to none. Very informative,
                      kept me up to date with all the proceedings. Extremely
                      professional. Can't praise highly enough for the
                      incredible work and amazing result.
                    </p>
                    <p>
                      {" "}
                      <b>Michael Crosbie</b>
                    </p>
                    <p>
                      {" "}
                      <b>Date of experience: September 09, 2024</b>{" "}
                    </p>
                  </a>
                </Carousel.Item>
                <Carousel.Item className="carousel-item">
                  <a target="_blank" rel="noopener noreferrer">
                    <div className="mb-3">
                      <img
                        src="https://dev.themoneysolicitor.com/static/media/stars-5.27f94caa.svg"
                        alt="Trustpilot star rating"
                        style={{ height: 24 }}
                      />
                    </div>
                    <h5> The service from TMS was second to none </h5>
                    <p>
                      {" "}
                      The service from TMS was second to none. Very informative,
                      kept me up to date with all the proceedings. Extremely
                      professional. Can't praise highly enough for the
                      incredible work and amazing result.
                    </p>
                    <p>
                      {" "}
                      <b>Michael Crosbie</b>
                    </p>
                    <p>
                      {" "}
                      <b>Date of experience: September 09, 2024</b>{" "}
                    </p>
                  </a>
                </Carousel.Item>
              {/* </div> */}
              {/* <div className="carousel-indicators pb-0 mb-0">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={0}
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={1}
                  aria-label="Slide 2"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={2}
                  aria-label="Slide 3"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={3}
                  aria-label="Slide 4"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={4}
                  aria-label="Slide 5"
                />
              </div> */}
            </Carousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default TrustPilot;
