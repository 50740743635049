import '../../../assets/OD_UFL_4_2/scss/main.scss'
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP.js";
import FbViewPixel from "../../../Utility/FbViewPixel.js";
import FormOD_UFL_4_2 from "../../Forms/FormOD_UFL_4_2.js";
import AccordSection from '../../Includes/Layouts/OD_UFL_4/AccordSection.js';
import Footer from "../../Includes/Layouts/OD_UFL_4_2/Footer.js";

const OD_UFL_4_2 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="OD_UFL_4_2" />
      <FbViewPixel />
      <div className="OD_UFL_4_2">
        <FormOD_UFL_4_2 />
        <AccordSection />
        <Footer />
      </div>
    </>
  )
}


export default OD_UFL_4_2;