export const Salutation = [
  {
    label: "Title",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Dr.",
    value: "Dr",
  },
  {
    label: "Prof.",
    value: "Prof",
  },
];

export const DobDay = [{ label: "Day", value: "" }];
const startDay = 1;
const endDay = 31;
for (var i = startDay; i <= endDay; i++) {
  var objDobDay = {};
  objDobDay["label"] = i;
  objDobDay["value"] = i;
  DobDay.push(objDobDay);
}

export const DobMonth = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const DobMonthV2 = [
  { label: "Month", value: "" },
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DobYear = [{ label: "Year", value: "" }];
const startYear = 2002;
const endYear = 1910;
for (var k = startYear; k >= endYear; k--) {
  var objDob = {};
  objDob["label"] = k;
  objDob["value"] = k;
  DobYear.push(objDob);
}

export const Amount = [
  { label: "Please Select the Amount", value: "" },
  { label: "£500", value: "£500" },
  { label: "£1000", value: "£1000" },
  { label: "£1500", value: "£1500" },
  { label: "£2000", value: "£2000" },
  { label: "£2500", value: "£2500" },
  { label: "£3000", value: "£3000" },
  { label: "£3500", value: "£3500" },
  { label: "£4000", value: "£4000" },
  { label: "£4500", value: "£4500" },
  { label: "£5000", value: "£5000" },
  { label: "£5500", value: "£5500" },
  { label: "£6000", value: "£6000" },
  { label: "£6500", value: "£6500" },
  { label: "£7000", value: "£7000" },
  { label: "£7500", value: "£7500" },
  { label: "£8000", value: "£8000" },
  { label: "£8500", value: "£8500" },
  { label: "£9000", value: "£9000" },
  { label: "£9500", value: "£9500" },
  { label: "£10000", value: "£10000" },
];

export const YearPossession = [{ label: "Select Year", value: "" }];
const startYearOfPossession = 2023;
const endYearOfPossession = 2006;
for (var k = startYearOfPossession; k >= endYearOfPossession; k--) {
  var objYearOfPossession = {};
  objYearOfPossession["label"] = k;
  objYearOfPossession["value"] = k;
  YearPossession.push(objYearOfPossession);
}

export const RecentCreditIncreaseYear = [{ label: "Select Year", value: "" }];
const startRecentCreditIncrease = 2024;
const endRecentCreditIncrease = 2000;
for (var k = startRecentCreditIncrease; k >= endRecentCreditIncrease; k--) {
  var objYearOfPossession = {};
  objYearOfPossession["label"] = k;
  objYearOfPossession["value"] = k;
  RecentCreditIncreaseYear.push(objYearOfPossession);
}
RecentCreditIncreaseYear.push({
  label: "Can't Remember",
  value: "Can't Remember",
});

export const ClosedYears = [{ label: "Select Year", value: "" }];
const startClosedYears = 2024;
const endClosedYears = 2000;
for (var k = startClosedYears; k >= endClosedYears; k--) {
  var objYearOfPossession = {};
  objYearOfPossession["label"] = k;
  objYearOfPossession["value"] = k;
  ClosedYears.push(objYearOfPossession);
}

export const ClosedYearsV3 = [{ label: "Select Year", value: "" }];
const startClosedYearsV3 = 2017;
const endClosedYearsV3 = 2000;
for (var k = startClosedYearsV3; k >= endClosedYearsV3; k--) {
  var objYearOfPossession = {};
  objYearOfPossession["label"] = k;
  objYearOfPossession["value"] = k;
  ClosedYearsV3.push(objYearOfPossession);
}

export const ClosedYears2 = [{ label: "Select Year", value: "" }];
const startClosedYear = 2024;
const endClosedYear = 2000;
for (var k = startClosedYears; k >= endClosedYears; k--) {
  var objYearOfPossession = {};
  objYearOfPossession["label"] = k;
  objYearOfPossession["value"] = k;
  ClosedYears2.push(objYearOfPossession);
}
ClosedYears2.push({ label: "Can't Remember", value: "Can't Remember" });

export const PossessionMonth = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const furterDe = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const Employmentsts = [
  { label: "Select employment status", value: "" },
  { label: "Employed", value: "3" },
  { label: "Unemployed", value: "199" },
  { label: "Self-employed", value: "4" },
  { label: "Student", value: "5" },
  { label: "Retired", value: "6" },
  { label: "Benefits main source of income", value: "200" },
  { label: "Part time job", value: "201" },
  { label: "Temporary worker", value: "202" },
  { label: "Long term sick", value: "203" },
  { label: "Maternity", value: "204" },
  { label: "Rolling/Zero hours contract", value: "205" },
];
export const EmploymentNDY = [
  { label: "Select employment status", value: "" },
  { label: "Employed", value: "3" },
  { label: "Unemployed", value: "199" },
  { label: "Self-employed", value: "4" },
  { label: "Student", value: "5" },
  { label: "Retired", value: "6" },
];

export const MonthlyIncome = [
  { label: "Select Monthly Income", value: "" },
  { label: "Less than £500", value: "Less than £500" },
  { label: "£500 - £1000", value: "£500 - £1000" },
  { label: "£1000 - £1500", value: "£1000 - £1500" },
  { label: "£1500 - £2000", value: "£1500 - £2000" },
  { label: "£2500 - £3000", value: "£2500 - £3000" },
  { label: "£3000 - £4000", value: "£3000 - £4000" },
  { label: "More than £4000", value: "More than £4000" },
];

export const MonthlyIncomeODFLP = [
  { label: "Select Monthly Income", value: "" },
  { label: "Less than £500", value: "269" },
  { label: "£500 - £1000", value: "270" },
  { label: "£1000 - £1500", value: "271" },
  { label: "£1500 - £2000", value: "272" },
  { label: "£2500 - £3000", value: "273" },
  { label: "£3000 - £4000", value: "274" },
  { label: "More than £4000", value: "275" },
];

export const DependentODFLP = [
  { label: "Please Select", value: "" },
  { label: "0", value: "7" },
  { label: "1", value: "8" },
  { label: "2", value: "9" },
  { label: "3", value: "279" },
  { label: "4", value: "280" },
  { label: "4+", value: "281" },
];

export const Dependent = [
  { label: "Please Select", value: "" },
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "4+", value: "4+" },
];
export const DependentV3 = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "4+", value: "4+" },
];

const BannerYearAdv1 = [];
const start = 1926;
const end = 1989;
for (var i = start; i <= end; i++) {
  if (start === i) {
    BannerYearAdv1.push([-i]);
  } else {
    BannerYearAdv1.push([i]);
  }
}
export { BannerYearAdv1 };
export const signCheckText = `By signing and by pressing “Submit” below, you agree to Check for SSB Group <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and that we may use your signature on the <a style="cursor:pointer; color:#00aab1 !important;" href=""> Letter of Authority</a> that we send to your bank.  A copy of the <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and <a style="cursor:pointer; color:#00aab1 !important" href="">Letter of Authority</a> will be sent to you via email.
`;

export const faq = [
  {
    id: 1,
    head: "What is a Packaged Bank Account (PBA)?",
    content:
      "<p>Customers have been charged a monthly fee for an account which has a bundle of extra insurances such as travel, smart phone and breakdown cover. However, it has been found that these insurances are not always suitable for the customer or that the customer might not always be able to make a claim on the policy, for example, if their health would prevent them from making a claim. It can sometimes be difficult for customers to make a claim on these policies due to restrictions in the small print. Some customers have found that they have no use for some or all of insurances sold but the bank failed to draw this to their attention.</p>",
  },
  {
    id: 2,
    head: "How do I know if I have a Packaged Bank Account?",
    content:
      "<p>A good place to start would be to look at your monthly bank statements to see if you have been paying a fee for your account. Different providers of Packaged Bank Accounts use different names to market this product so your statement will not necessarily always make it clear as to the nature of the account. If in any doubt over a charge you can clarify it with your bank.  If you previously held an account and have downgraded or closed it, you can still claim.</p",
  },
  {
    id: 3,
    head: "Will making a claim affect my relationship with the bank?",
    content:
      "<p>The claim will not affect the relationship you have with the bank. The bank has a duty of care to treat you fairly and therefore you would not be treated any differently for having made a claim.</p>",
  },
  {
    id: 4,
    head: "What fee is charged for the service?",
    content:
      "<p>The first point to make clear is that no fee is payable unless we are successful in winning compensation for you. Therefore, we work on a strictly no win, no fee basis. In the event that we secure you a positive outcome leading to compensation, our fee will be a 39.99% of any redress payment inclusive of VAT.</p>",
  },
  {
    id: 5,
    head: "How much compensation can I expect if the Packaged Bank Account was mis-sold?",
    content:
      "<p>If the Packaged Bank Account is established as mis-sold normal practice would be to refund either all or part of the premiums you have made for the account and add 8% interest on top.</p>",
  },
  {
    id: 1,
    head: "Can I make a claim if my Packaged Bank Account is closed?",
    content:
      "<p>Yes, just because an account is closed, or downgraded to a free account does not prevent you from claiming.</p>",
  },
  {
    id: 1,
    head: "How long will the claim take?",
    content:
      "<p>This depends of the provider of the account and the work load they are currently experiencing. Their regulator gives 8 weeks to give a response to your claim. If a response is not forthcoming within this time frame, the claim can be referred to the Financial Ombudsman Service.</p>",
  },
  {
    id: 1,
    head: "Can I make a claim myself?",
    content:
      "<p>Yes, you can go directly to your provider and lodge a claim if you wish. However, our service has been specifically designed to take the hassle and stress out of doing this by yourself. Our process will help you to present a claim that reflects all your key claim points to aid you win any potential redress. The aim is to make sure that your claim is taken seriously. Please note that the Financial Ombudsman Scheme could be used or any other mechanism such as legal expenses insurance to pursue a claim.</p>",
  },
];

export const accCloseYear = [{ label: "Select Year", value: "" }];
const startAccYear = 2023;
const endAccYear = 1980;
for (var k = startAccYear; k >= endAccYear; k--) {
  var objAccYear = {};
  objAccYear["label"] = k;
  objAccYear["value"] = k;
  accCloseYear.push(objAccYear);
}

export const VeryLenderArray = {
  133: "Very",
  134: "Shop Direct",
  135: "Littlewoods",
};

export const MonthNumberArray = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

export const MonthNameArray = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

export const VanquisProducts = {
  164: "Vanquis Classic Card",
  165: "Vanquis Chrome Card",
  166: "Vanquis Origin Card",
  167: "Vanquis Personal Loan",
  168: "Not Sure",
  169: "John Lewis & Partners",
  170: "Argos",
  171: "AO",
  172: "Newpay",
  173: "Aquacard",
  174: "Marbles",
  175: "Bip",
  176: "Fluid",
  177: "Pulse",
  178: "New Day Ltd", //other
};

export const ReliantBorrowing = {
  156: "I had to take out additional lending to pay for the credit",
  158: "I already had multiple other borrowings at the time I applied for this credit",
  159: "I was in arrears with other lenders when I took the credit",
  216: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit",
  218: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit",
  219: "My overdraft was being used to fund my credit card payments",
  221: "I was borrowing off friends or family leading up to or while holding the credit",
  103: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
  105: "I already had multiple other loans at the time I applied for this loan",
  106: "I was in arrears with other lenders when I took the finance",
};

export const Overindebtness = {
  156: "I had to take out additional lending to pay for the credit",
  158: "I already had multiple other borrowings at the time I applied for this credit",
  159: "I was in arrears with other lenders when I took the credit",
  216: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit",
  218: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit",
  219: "My overdraft was being used to fund my credit card payments",
  220: "I used my credit card to make cashpoint withdrawals",
  221: "I was borrowing off friends or family leading up to or while holding the credit",
  222: "I was close to, or exceeding my credit limit, and using the money for essential spending",
  224: "I was sometimes being charged late payment fees on my credit card",
  238: "Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company? Yes",
  103: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
  105: "I already had multiple other loans at the time I applied for this loan",
  106: "I was in arrears with other lenders when I took the finance",
};

export const DebtEssentialBills = {
  159: "I was in arrears with other lenders when I took the credit",
  107: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
  160: "I was in arrears with my household bills when I took the credit",
  106: "I was in arrears with other lenders when I took the finance",
  108: "I was in arrears with my household bills when I took the finance",
};

export const CreditFileInformation = {
  99: "Yes I have struggled to repay",
  159: "I was in arrears with other lenders when I took the credit",
  107: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
  160: "I was in arrears with my household bills when I took the credit",
  110: "I regularly missed my monthly repayments",
  215: "I was sometimes not managing to make the minimum monthly payment required on my credit card",
  216: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit",
  222: "I was close to, or exceeding my credit limit, and using the money for essential spending",
  224: "I was sometimes being charged late payment fees on my credit card",
  238: "Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company? Yes",
  206: "Has Vanquis sold on your debt, if any, to a company such as Lowell Portfolio / Arrow Global? Yes",
  141: "How often would you have a balance which was close to, or exceeding your credit limit? Always",
  142: "How often would you have a balance which was close to, or exceeding your credit limit? Sometimes",
  143: "How often would you have a balance which was close to, or exceeding your credit limit? Twice",
  144: "How often would you have a balance which was close to, or exceeding your credit limit? Once",
  106: "I was in arrears with other lenders when I took the finance",
  108: "I was in arrears with my household bills when I took the finance",
};

export const ImpactedNegatively = {
  99: "Yes I have struggled to repay",
  156: "I had to take out additional lending to pay for the credit",
  157: "The lender didn’t ask me any questions to confirm that I would be able to repay the credit in a sustainable way",
  158: "I already had multiple other borrowings at the time I applied for this credit",
  159: "I was in arrears with other lenders when I took the credit",
  107: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
  160: "I was in arrears with my household bills when I took the credit",
  161: "My income changed between taking out and repaying the credit but the lender didn’t check",
  110: "I regularly missed my monthly repayments",
  215: "I was sometimes not managing to make the minimum monthly payment required on my credit card",
  216: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit",
  217: "I regularly used on-line betting/gambled, leading up to or while holding the credit",
  218: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit",
  219: "My overdraft was being used to fund my credit card payments",
  220: "I used my credit card to make cashpoint withdrawals",
  221: "I was borrowing off friends or family leading up to or while holding the credit",
  222: "I was close to, or exceeding my credit limit, and using the money for essential spending",
  223: "My income while holding the credit was not always reliable",
  224: "I was sometimes being charged late payment fees on my credit card",
  238: "Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company? Yes",
  206: "Has Vanquis sold on your debt, if any, to a company such as Lowell Portfolio / Arrow Global? Yes",
  141: "How often would you have a balance which was close to, or exceeding your credit limit? Always",
  142: "How often would you have a balance which was close to, or exceeding your credit limit? Sometimes",
  103: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
  104: "The lender didn’t ask me any questions to confirm that I would be able to repay the loan in a sustainable way",
  105: "I already had multiple other loans at the time I applied for this loan",
  106: "I was in arrears with other lenders when I took the finance",
  108: "I was in arrears with my household bills when I took the finance",
  109: "My income changed between taking out and repaying the loan but the lender didn’t check",
};

export const HighUtilisation = {
  156: "I had to take out additional lending to pay for the credit",
  158: "I already had multiple other borrowings at the time I applied for this credit",
  159: "I was in arrears with other lenders when I took the credit",
  216: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit",
  218: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit",
  219: "My overdraft was being used to fund my credit card payments",
  220: "I used my credit card to make cashpoint withdrawals",
  221: "I was borrowing off friends or family leading up to or while holding the credit",
  222: "I was close to, or exceeding my credit limit, and using the money for essential spending",
  238: "Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company? Yes",
  206: "Has Vanquis sold on your debt, if any, to a company such as Lowell Portfolio / Arrow Global? Yes",
  103: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
  105: "I already had multiple other loans at the time I applied for this loan",
  106: "I was in arrears with other lenders when I took the finance",
};

export const StruggledRepayments = {
  99: "Yes I have struggled to repay",
  156: "I had to take out additional lending to pay for the credit",
  159: "I was in arrears with other lenders when I took the credit",
  107: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
  160: "I was in arrears with my household bills when I took the credit",
  110: "I regularly missed my monthly repayments",
  215: "I was sometimes not managing to make the minimum monthly payment required on my credit card",
  216: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit",
  218: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit",
  219: "My overdraft was being used to fund my credit card payments",
  221: "I was borrowing off friends or family leading up to or while holding the credit",
  224: "I was sometimes being charged late payment fees on my credit card",
  238: "Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company? Yes",
  206: "Has Vanquis sold on your debt, if any, to a company such as Lowell Portfolio / Arrow Global? Yes",
  141: "How often would you have a balance which was close to, or exceeding your credit limit? Always",
  142: "How often would you have a balance which was close to, or exceeding your credit limit? Sometimes",
  103: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
  106: "I was in arrears with other lenders when I took the finance",
  108: "I was in arrears with my household bills when I took the finance",
};

export const ClientFinancialArrears = {
  99: "Yes I have struggled to repay",
  156: "I had to take out additional lending to pay for the credit",
  159: "I was in arrears with other lenders when I took the credit",
  107: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
  160: "I was in arrears with my household bills when I took the credit",
  161: "My income changed between taking out and repaying the credit but the lender didn’t check",
  110: "I regularly missed my monthly repayments",
  215: "I was sometimes not managing to make the minimum monthly payment required on my credit card",
  216: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit",
  218: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit",
  219: "My overdraft was being used to fund my credit card payments",
  221: "I was borrowing off friends or family leading up to or while holding the credit",
  224: "I was sometimes being charged late payment fees on my credit card",
  238: "Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company? Yes",
  206: "Has Vanquis sold on your debt, if any, to a company such as Lowell Portfolio / Arrow Global? Yes",
  141: "How often would you have a balance which was close to, or exceeding your credit limit? Always",
  142: "How often would you have a balance which was close to, or exceeding your credit limit? Sometimes",
  103: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
  106: "I was in arrears with other lenders when I took the finance",
  108: "I was in arrears with my household bills when I took the finance",
  109: "My income changed between taking out and repaying the loan but the lender didn’t check",
};

export const CardNotManaged = {
  99: "Yes I have struggled to repay",
  156: "I had to take out additional lending to pay for the credit",
  110: "I regularly missed my monthly repayments",
  215: "I was sometimes not managing to make the minimum monthly payment required on my credit card",
  216: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit",
  218: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit",
  220: "I used my credit card to make cashpoint withdrawals",
  224: "I was sometimes being charged late payment fees on my credit card",
  238: "Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company? Yes",
  206: "Has Vanquis sold on your debt, if any, to a company such as Lowell Portfolio / Arrow Global? Yes",
  141: "How often would you have a balance which was close to, or exceeding your credit limit? Always",
  142: "How often would you have a balance which was close to, or exceeding your credit limit? Sometimes",
  103: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
};

export const UnableRepayments = {
  99: "Yes I have struggled to repay",
  156: "I had to take out additional lending to pay for the credit",
  157: "The lender didn’t ask me any questions to confirm that I would be able to repay the credit in a sustainable way",
  161: "My income changed between taking out and repaying the credit but the lender didn’t check",
  110: "I regularly missed my monthly repayments",
  215: "I was sometimes not managing to make the minimum monthly payment required on my credit card",
  216: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit",
  218: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit",
  219: "My overdraft was being used to fund my credit card payments",
  220: "I used my credit card to make cashpoint withdrawals",
  221: "I was borrowing off friends or family leading up to or while holding the credit",
  223: "My income while holding the credit was not always reliable",
  224: "I was sometimes being charged late payment fees on my credit card",
  238: "Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company? Yes",
  206: "Has Vanquis sold on your debt, if any, to a company such as Lowell Portfolio / Arrow Global? Yes",
  103: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
  104: "The lender didn’t ask me any questions to confirm that I would be able to repay the loan in a sustainable way",
  109: "My income changed between taking out and repaying the loan but the lender didn’t check",
};

export const TopSelectedLenders = [
  { label: "Barclays", value: "Barclays" },
  { label: "NatWest", value: "Natwest" },
  { label: "Halifax", value: "Halifax" },
  { label: "Lloyds", value: "Lloyds" },
  { label: "Santander", value: "Santander" },
  { label: "Nationwide", value: "Nationwide" },
  { label: "HSBC", value: "HSBC" },
];

export const lenderSuggest = [
  "Cashplus Bank",
  "Aldermore Bank",
  "Allica Bank",
  "Allied Irish Bank (GB)",
  "BOI",
  "Cabot Credit Management Group Limited",
  "Clydesdale Bank",
  "Co-operative Bank",
  "FirstTrust",
  "Hampden & Co",
  "Handelsbanken",
  "Intrum Uk Finance Limited",
  "Isle of Man Bank",
  "Kroo LTD",
  "Lowell",
  "Marks and Spencer",
  "MBNA",
  "Metro Bank",
  "Paragon Bank",
  "Pra Group Uk",
  "Reliance Bank",
  "The co-operative bank",
  "Triodos Bank",
  "Weatherbys Bank Ltd",
  "Yorkshire Bank",
  "Zempler Bank Limited"
];


export const ReliantBorrowingOverDraft = {
  102: "Did you take out further borrowing so that you could manage your finances? Yes",
};

export const OverindebtnessOverDraft = {
  251: "Did you ever exceed your credit limit?",
  79: "When I took the overdraft I was already paying monthly for one or more of the following:",
  102: "Did you take out further borrowing so that you could manage your finances? Yes",
  288: "I had not maintained a credit balance for a significant period of time which ought to have made the bank aware of my financial difficulties - do you agree?",
};

export const DebtEssentialBillsOverDraft = {
  265: "I have suffered financial hardship due to interest and charges being applied to my account - do you agree?",
  286: "Have you struggled to meet your essential repayments as a result of being in your overdraft?",
};

export const CreditFileInformationOverDraft = {
  251: "Did you ever exceed your credit limit?",
  96: "How often did you exceed your credit limit?",
  97: "Did you receive overlimit charges?",
  286: "Have you struggled to meet your essential repayments as a result of being in your overdraft?",
  288: "I had not maintained a credit balance for a significant period of time which ought to have made the bank aware of my financial difficulties - do you agree?",
  105: "I was stuck in my overdraft and didn’t see a positive balance",
};

export const ImpactedNegativelyOverDraft = {
  249: "The bank acted irresponsibly by increasing my overdraft limit without conducting a proper affordability assessment - do you agree?",
  251: "Did you ever exceed your credit limit?",
  96: "How often did you exceed your credit limit?",
  97: "Did you receive overlimit charges?",
  265: "I have suffered financial hardship due to interest and charges being applied to my account - do you agree?",
  79: "When I took the overdraft I was already paying monthly for one or more of the following:",
  102: "Did you take out further borrowing so that you could manage your finances? Yes",
  286: "Have you struggled to meet your essential repayments as a result of being in your overdraft?",
  288: "I had not maintained a credit balance for a significant period of time which ought to have made the bank aware of my financial difficulties - do you agree?",
  105: "I was stuck in my overdraft and didn’t see a positive balance",
};

export const HighUtilisationOverDraft = {
  251: "Did you ever exceed your credit limit?",
  96: "How often did you exceed your credit limit?",
  97: "Did you receive overlimit charges?",
  288: "I had not maintained a credit balance for a significant period of time which ought to have made the bank aware of my financial difficulties - do you agree?",
  105: "I was stuck in my overdraft and didn’t see a positive balance",
};

export const StruggledRepaymentsOverDraft = {
  265: "I have suffered financial hardship due to interest and charges being applied to my account - do you agree?",
  102: "Did you take out further borrowing so that you could manage your finances? Yes",
  286: "Have you struggled to meet your essential repayments as a result of being in your overdraft?",
  288: "I had not maintained a credit balance for a significant period of time which ought to have made the bank aware of my financial difficulties - do you agree?",
  105: "I was stuck in my overdraft and didn’t see a positive balance",
};

export const ClientFinancialArrearsOverDraft = {
  251: "Did you ever exceed your credit limit?",
  96: "How often did you exceed your credit limit?",
  265: "I have suffered financial hardship due to interest and charges being applied to my account - do you agree?",
  102: "Did you take out further borrowing so that you could manage your finances? Yes",
  286: "Have you struggled to meet your essential repayments as a result of being in your overdraft?",
  288: "I had not maintained a credit balance for a significant period of time which ought to have made the bank aware of my financial difficulties - do you agree?",
};

export const AccNotManagedOverDraft = {
  251: "Did you ever exceed your credit limit?",
  96: "How often did you exceed your credit limit?",
  97: "Did you receive overlimit charges?",
  265: "I have suffered financial hardship due to interest and charges being applied to my account - do you agree?",
  288: "I had not maintained a credit balance for a significant period of time which ought to have made the bank aware of my financial difficulties - do you agree?",
  105: "I was stuck in my overdraft and didn’t see a positive balance",
};

export const UnableRepaymentsOverDraft = {
  288: "I had not maintained a credit balance for a significant period of time which ought to have made the bank aware of my financial difficulties - do you agree?",
  105: "I was stuck in my overdraft and didn’t see a positive balance",
};

export const HighestCreditLimitOption = [
  { label: "Select Amount", value: "" },
  { label: "less than £250", value: "less than £250" },
  { label: "£250 - £500", value: "£250 - £500" },
  { label: "£500 - £1,000", value: "£500 - £1,000" },
  { label: "£1,000 - £1,500", value: "£1,000 - £1,500" },
  { label: "£1,500 - £2,000", value: "£1,500 - £2,000" },
  { label: "£2,000 - £2,500", value: "£2,000 - £2,500" },
  { label: "£2,500 - £3,000", value: "£2,500 - £3,000" },
  { label: "£3,000 - £3,500", value: "£3,000 - £3,500" },
  { label: "£3,500 - £4,000", value: "£3,500 - £4,000" },
  { label: "£4,000 - £4,500", value: "£4,000 - £4,500" },
  { label: "£4,500 - £5,000", value: "£4,500 - £5,000" },
  { label: "Over £5,000", value: "Over £5,000" },
];

export const OverDraftBankList = [
  { value: "Barclays", label: "Barclays" },
  { value: "Bank of Scotland", label: "Bank of Scotland" },
  { value: "Clydesdale Bank", label: "Clydesdale Bank" },
  { value: "Halifax", label: "Halifax" },
  { value: "HSBC", label: "HSBC" },
  { value: "Lloyds", label: "Lloyds" },
  { value: "NatWest", label: "NatWest" },
  { value: "Royal Bank of Scotland", label: "Royal Bank of Scotland" },
  { value: "Santander", label: "Santander" },
  { value: "TSB", label: "TSB" },
  { value: "Yorkshire Bank", label: "Yorkshire Bank" }
];

export const OpeningCreditLimitOption = [
  { label: "Select Amount", value: "" },
  { label: "£100", value: "£100" },
  { label: "£250", value: "£250" },
  { label: "£500", value: "£500" },
  { label: "£1,000", value: "£1,000" },
  { label: "£1,500", value: "£1,500" },
  { label: "£2,000", value: "£2,000" },
  { label: "£2,500", value: "£2,500" },
  { label: "£5,000", value: "£5,000" },
  { label: "£10,000", value: "£10,000" },
  { label: "£15,000", value: "£15,000" },
  { label: "£20,000", value: "£20,000" },
  { label: "£25,000 or More", value: "£25,000 or More" },
];
