import React from "react";
import SelectBox from "../../../../UI/SelectBox";
import { MonthlyIncomeODFLP } from "../../../../../Constants/Constants";

const MonthlyIncome = ({
    reff,
    validation,
    validationMsg,
    trigger,
    onselectAnswer,
}) => {

    const optionSelected = async (e) => {
        let errorFlag = 0;
        let result = await trigger("cardMonthlyIncome");

        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            onselectAnswer(e);
        }
    };

    return (
        <>
            <div className={`radio-box animated fadeInUp`} ref={reff}>
                <h3>
                    <b>
                        When you took out your overdraft, what was your monthly income?
                    </b>
                </h3>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                    <SelectBox
                        className="form-select form-control"
                        OptionValue={MonthlyIncomeODFLP}
                        name="cardMonthlyIncome"
                        id="cardMonthlyIncome"
                        myRef={validation({
                            required: "Please select your monthly income",
                        })}
                        validationMsg={
                            validationMsg.cardMonthlyIncome &&
                            validationMsg.cardMonthlyIncome.message
                        }
                    />
                </div>
                <div className="col-xl-12 col-lg-12 text-center p-0">
                    <button
                        type="button"
                        className="btn-next"
                        onClick={optionSelected}
                        data-quest-id={101}
                        data-ans-id={null}
                        data-ans-label={null}
                        name="cardMonthlyIncome"
                    >{`NEXT`}</button>
                </div>
            </div>
        </>
    );
};

export default MonthlyIncome;

