import React, { useState, useRef } from 'react';
import CheckBox from '../../../../UI/CheckBox';

const WereAlreadyPaying = ({
    reff,
    display,
    onselectAnswer,
    checklistOptions,
    questArr,
    setChecklistOptions,
}) => {
    const [showError, setshowError] = useState("hide");
    const checkboxRefs = useRef({});

    const labelclicked = async (e) => {
        let selectedCount = checklistOptions.length;
        if (selectedCount == 0) {
            setshowError("show");
        } else {
            setChecklistOptions(checklistOptions);
            onselectAnswer(e);
        }
    };

    const onSelectOption = (e) => {
        if (e.target.checked === true) {
            setshowError("hide");
            checklistOptions.push({ id: +e.target.value });
        } else {
            let deselect = e.target.value;
            const decre = checklistOptions.map((OptionArray, index) => {
                if (deselect == OptionArray.id) {
                    checklistOptions.splice(index, 1);
                }
                if (checklistOptions.length == 0) {
                    checkBoxEnable();
                }
            });
        }
    };

    let checkArray = questArr.answers;
    const questionId = questArr.questionId ? questArr.questionId : 0;
    const lenderCheckBox = checkArray.map((checArr, index) => {
        return (
            <li className="" key={checArr.value} ref={(e) => checkboxRefs.current[checArr.value] = e}>
                <CheckBox
                    dataId={checArr.value}
                    name="wereAlreadyPaying[]"
                    onChange={onSelectOption}
                    className="custom-checkbox"
                    CheckBoxText={checArr.label}
                    value={checArr.value}
                />
            </li>
        );
    });

    const checkBoxEnable = () => {
        checkArray.map((checArr, index) => {
            checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
            checkboxRefs.current[checArr.value].childNodes[1].classList.remove("disabledcheckbox");
        })
    }

    return (
        <>
            <div style={{ 'display': display }}>
                <div className="radio-box question animated fadeInUp" ref={reff} >
                    <h3 style={{ color: '#69a2d7' }} className='text-center'><b>
                        When you took out the overdraft, were you already paying monthly for one or more of the following:
                    </b></h3>
                    <ul className="options secondop">{lenderCheckBox}</ul>

                    <div className="col-xl-12 col-lg-12 text-center p-0" >
                        <span className={`error_msg ${showError}`} id="email_err">
                            Please select at least one
                        </span>
                        <button
                            type="button"
                            className="btn-next"
                            onClick={labelclicked}
                            data-quest-id={79}
                            data-ans-id={null}
                            data-ans-label={null}
                            name="wereAlreadyPaying"
                        >{`Next`}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WereAlreadyPaying;