import React from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const OverLimitCharges = ({ onselectAnswer, reff, display }) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
            Did you receive overlimit charges?
          </h3>
          <RadioButtonQuest
            key="Always146"
            className="radio-buttons"
            labelName="Always"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="261"
            name="overLimitCharge"
            id="opt261"
            dataQuestId={97}
            dataAnsId={261}
            dataAnsLabel="Always"
          />
          <RadioButtonQuest
            key="Sometimes147"
            className="radio-button"
            labelName="Sometimes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="262"
            name="overLimitCharge"
            id="opt262"
            dataQuestId={97}
            dataAnsId={262}
            dataAnsLabel="Sometimes"
          />
          <RadioButtonQuest
            key="Rarely148"
            className="radio-button"
            labelName="Rarely"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="263"
            name="overLimitCharge"
            id="opt263"
            dataQuestId={97}
            dataAnsId={263}
            dataAnsLabel="Rarely"
          />
          <RadioButtonQuest
            key="Never149"
            className="radio-button"
            labelName="Never"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="264"
            name="overLimitCharge"
            id="opt264"
            dataQuestId={97}
            dataAnsId={264}
            dataAnsLabel="Never"
          />
        </div>
      </div>
    </>
  );
};

export default OverLimitCharges;
