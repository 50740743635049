import faqIcon from "../../../../assets/OD_UFL_4/img/icon-faq.png";
import Accordion from "react-bootstrap/Accordion";

const AccordSection = () => {
  return (
    <section className="faqsection py-5">
      <div className="container">
        <div className="row title-sec">
          <div className="col-lg-2 col-md-2 col-sm-2 col-3 text-center">
            <img src={faqIcon} alt="" />
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-9 ps-lg-0 ps-md-0 ">
            <h2 className="pt-lg-3 pt-md-3 pt-sm-3 pt-0">
              Your questions about TMS Responsible Lending answered
            </h2>
            <p>
              Your questions about TMS Responsible Lending answered Click on a
              question below to view the answer.
            </p>
          </div>
        </div>
        <Accordion
          defaultActiveKey="0"
          className="my-lg-5 my-md-5 my-sm-4 my-3"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              How much time does the claims process take?
            </Accordion.Header>
            <Accordion.Body>
              Generally, it will take approximately 8 to 12 weeks from receipt
              of your claim form until your payout. However, if a lender
              disputes any part of a claim and it is referred to the Financial
              Ombudsman Service (FOS), it could extend your claim period up to
              12 months.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Are there any upfront fees?</Accordion.Header>
            <Accordion.Body>
              'No, we will not ask for any money upfront. We’ll start your claim for free and if your claim is successful, you would be paying our commission that starts at 36% (including VAT) and going as low as 18% (including VAT) as laid out in our Terms of Business Agreement.'
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Is there a deadline to file a claim?
            </Accordion.Header>
            <Accordion.Body>
              It is generally 6 years from the event being complained about or, if later, three years from
              when you knew or ought reasonably to have known you had cause to complain. Despite this,
              Section 140 of the Consumer Credit Act 1974 can also potentially mean that your complaint
              is brought in time despite the afore mentioned.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              What evidence is required to get started?
            </Accordion.Header>
            <Accordion.Body>
              Getting started is quite easy. We simply need you to provide some basic information such
              as your full name, the address where you resided during the term of the overdraft and the
              name of your lender. Credit Files and Bank statements can be very useful. However, we can
              request these for you.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
            Can I still file a claim if I’ve had Overdrafts from more than one lender?
            </Accordion.Header>
            <Accordion.Body>
              Yes! You’ll have the opportunity to let us know when filling out your application. We will
              check if you had a credit from any number of lenders and contact each lender for you, if you
              instruct us to!
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              Will the claim impact my Credit Score?
            </Accordion.Header>
            <Accordion.Body>
            Normal practice is for the lender to remove any adverse information reported to credit
            reference agencies about the lending in question that has been upheld.
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="6">
            <Accordion.Header>
              Should I expect you to contact my employer?
            </Accordion.Header>
            <Accordion.Body>
              No, we will never contact your employer regarding your claim. Our
              claims process is confidential and we will only share the
              information you provide with your lender(s), Credit Reference
              Agencies and the Financial Ombudsman (FOS) if required.
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              Do I need to continue paying the lender while the claim is
              ongoing?
            </Accordion.Header>
            <Accordion.Body>
              Yes, you should continue making payments to your lender during
              this time unless you’ve been told otherwise by the lender or
              administrator. Failing to continue your payments can result in a
              negative credit rating and the possibility of debt collection
              agencies getting involved.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              Is it possible to get a refund without using a Solicitor?
            </Accordion.Header>
            <Accordion.Body>
              Yes, you can file a claim for free without using a Solicitor/Claims Management Company by
              communicating directly with your lender(s) and raising your complaint points. If your
              complaint is rejected, you can send your claim to the Financial Ombudsman Service for
              free.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              Why should I use a Solicitor to file a claim?
            </Accordion.Header>
            <Accordion.Body>
              Filing yourself can be time consuming or potentially stressful! You can save yourself from the
              hassle by using our service. We have an experienced team of professionals that are ready to
              manage your claim.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              Will you keep me updated throughout the process?
            </Accordion.Header>
            <Accordion.Body>
              Yes, our team will contact you during the initial 8 week period to
              update you on the status of your claim. Once the lender has
              contacted us, we’ll contact you again to let you know. Our team
              will be ready to answer any questions you may have throughout the
              process. In the event that your case goes to the Financial
              Ombudsman Service (FOS), we will be in touch to let you know what
              to expect.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  );
};

export default AccordSection;
