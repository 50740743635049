import React, { useState } from "react";
import TextArea from "../../../../UI/TextArea";

const HowStuckAffects = ({
    clsName,
    reff,
    display,
    validation,
    validationMsg,
    getValues,
    trigger,
    setError,
    saveInputAns,
}) => {

    const optionSelected = async (e) => {
        let errorFlag = 0;
        let result = await trigger("howStuckAffectsbox");
        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            const fieldResult = getValues();
            var affect = fieldResult["howStuckAffectsbox"].trim();
            if (affect == "" || affect.length < 3) {
                setError("howStuckAffectsbox", {
                    type: "manual",
                    message: "Please enter",
                });
                return false;
            } else {
                saveInputAns(e);
            }
        }
    };

    return (
        <>
            <div style={{ display: display }}>
                <div className={`radio-box question animated fadeInUp ${clsName}`} ref={reff}>
                    <h3>You've told us you were stuck in your overdraft as a result you didn't see a frequent positive balance- how did this affect you?
                        <b>(Eg..Anxiety,Stress,Depression,Physical Ailments,Mental Health)</b>
                    </h3>
                    <div className="input-group mb-3">
                        <TextArea
                            type="textarea"
                            className="form-control"
                            row={4}
                            name="howStuckAffectsbox"
                            validation={validation({
                                required: "Please Enter",
                                minLength: {
                                    value: 3,
                                    message: "Please Enter",
                                },
                            })}
                            validationMsg={
                                validationMsg.howStuckAffectsbox &&
                                validationMsg.howStuckAffectsbox.message
                            }
                        ></TextArea>
                    </div>
                    <div className="col-xl-12 col-lg-12 text-center p-0">
                        <button
                            type="button"
                            className="btn-next"
                            onClick={optionSelected}
                            data-quest-id={106}
                            data-ans-id={null}
                            data-ans-label={null}
                            name="howStuckAffects"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HowStuckAffects;
