export const Lender = [
  { id:1, label:"Lender-1", value:1},
  { id:2, label:"Lender-2", value:2},
  { id:3, label:"Lender-3", value:3},
  { id:4, label:"Lender-4", value:4},
  { id:5, label:"Lender-5", value:5},
  { id:6, label:"Lender-6", value:6},
  { id:7, label:"Lender-7", value:7}
];
export const BankList = [
  { value: "",  label: "---Select Bank Name---" },
  { value: 181,  label: "Coutts" },
  { value: 182,  label: "Cumberland" },
  { value: 183,  label: "Danske Bank" },
  { value: 184,  label: "First Direct Bank" },
  { value: 185,  label: "Lloyds" },
  { value: 186,  label: "M&S Bank" },
  { value: 187,  label: "NatWest" },
  { value: 188,  label: "Open Banking Model" },
  { value: 189,  label: "RBS" },
  { value: 190,  label: "TSB" },
  { value: 191,  label: "Tesco" },
  { value: 192,  label: "Ulster Bank NI" },
  { value: 193,  label: "Wise" },
  { value: 194,  label: "Chase" }
];

export const ODMainLenders = [
  { value: 'Lloyds', label: 'Lloyds' },
  { value: 'Halifax', label: 'Halifax' },
  { value: 'Barclays', label: 'Barclays' },
  { value: 'Santander', label: 'Santander' },
  { value: 'HSBC', label: 'HSBC' },
  { value: 'Bank of Scotland', label: 'Bank of Scotland' },
];

export const ODMoreLenders = [
    { value: 'Royal Bank of Scotland', label: 'Royal Bank of Scotland' },
    { value: 'NatWest', label: 'NatWest' },
    { value: 'TSB', label: 'TSB' },
    { value: 'Yorkshire Bank', label: 'Yorkshire Bank' },
    { value: 'Clydesdale Bank', label: 'Clydesdale Bank' },
];
