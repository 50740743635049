import React from 'react'

const StruggledRepayments = ({
    onSelectAnswer,
    backClick,
    strugleRepaymentsBck
    }) => {

    const optionHandle = async (e) => {

        if (e.target.value === "286") {
            onSelectAnswer(e);
        } else if (e.target.value === "287") {
            onSelectAnswer(e);
        }

    }
    let toggle;
    if (strugleRepaymentsBck == "313") {
        toggle = 'didtakefurtherborrowing';
      } else if(strugleRepaymentsBck == "312") {
        toggle = 'furtherborrowing';
      }
  return (
    <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
    <div className="row">
        <div className="col-6">
            <div className=" mb-2">
                <button
                    type="button"
                    className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn`}
                    onClick={optionHandle}
                    value="286"
                    name="struggledrepayments"
                    id="nextscroll"
                    data-quest-id={103}
                    data-ans-id={286}
                    data-ans-label={null}
                >Yes
                </button>
            </div>
        </div>
        <div className="col-6">
            <div className=" mb-2">
                <button
                    type="button"
                    className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue`}
                    onClick={optionHandle}
                    value="287"
                    name="struggledrepayments"
                    id="nextscroll"
                    data-quest-id={103}
                    data-ans-id={287}
                    data-ans-label={null}
                >No
                </button>
            </div>
        </div>
    </div>
    <p className="text-center bck-marwid">
        <button
            name={toggle}
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
        >
            Go Back
        </button>
    </p>
</div>
  )
}

export default StruggledRepayments