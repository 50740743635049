import React from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const MaintainedCreditBalance = ({ onselectAnswer, reff, display }) => {

    const optionHandle = async (e) => {
        onselectAnswer(e);
    }

    return (
        <>
            <div style={{ display: display }}>
                <div className="radio-box question animated fadeInUp" ref={reff}>
                    <h3>
                        I had not maintained a credit balance for a significant perion of time which ought to have made the bank aware of my financial difficulties - do you agree?
                    </h3>
                    <RadioButtonQuest
                        key="Agree"
                        className="radio-button"
                        labelName="Agree"
                        labelClassName="option_but next-2"
                        onClick={optionHandle}
                        value="288"
                        name="maintainedCreditBalance"
                        id="agree288"
                        dataQuestId={104}
                        dataAnsId={288}
                        dataAnsLabel="Agree"
                    />
                    <RadioButtonQuest
                        key="Disagree"
                        className="radio-button"
                        labelName="Disagree"
                        labelClassName="option_but next-2"
                        onClick={optionHandle}
                        value="289"
                        name="maintainedCreditBalance"
                        id="disagree289"
                        dataQuestId={104}
                        dataAnsId={289}
                        dataAnsLabel="Disagree"
                    />
                </div>
            </div>
        </>
    );
};

export default MaintainedCreditBalance;
