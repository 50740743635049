import React from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const EverIncreCredLimit = ({ onselectAnswer, reff, display }) => {

    const optionHandle = async (e) => {
        onselectAnswer(e);
    }

    return (
        <>
            <div style={{ display: display }}>
                <div className="radio-box question animated fadeInUp" ref={reff}>
                    <h3>
                        Was your credit limit ever increased?
                    </h3>
                    <RadioButtonQuest
                        key="Yes"
                        className="radio-button"
                        labelName="Yes"
                        labelClassName="option_but next-2"
                        onClick={optionHandle}
                        value="247"
                        name="everincresedCredLimit"
                        id="yes"
                        dataQuestId={92}
                        dataAnsId={247}
                        dataAnsLabel="Yes"
                    />
                    <RadioButtonQuest
                        key="No"
                        className="radio-button"
                        labelName="No"
                        labelClassName="option_but next-2"
                        onClick={optionHandle}
                        value="248"
                        name="everincresedCredLimit"
                        id="no"
                        dataQuestId={92}
                        dataAnsId={248}
                        dataAnsLabel="No"
                    />
                </div>
            </div>
        </>
    );
};

export default EverIncreCredLimit;
