import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { wereUalreadyPaying } from "../../../../Constants/Questions";
import { useHistory } from "react-router-dom";
import { useUserVerify } from "../../../../Hooks/useUserVerify";
import { userMilestoneUpdate } from "../../../../Hooks/userMilestoneUpdate";
import { queryString } from "../../../../Utility/QueryString";
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
import QuestionnaireFooter from "./QuestionnaireFooter";
import AnalyzeModal from "./AnalayzeModal";
import OpeningCreditLimit from "./OverDraft/OpeningCreditLimit";
import EverIncreCredLimit from "./OverDraft/EverIncreCredLimit";
import BankActedIrresponsibly from "./OverDraft/BankActedIrresponsibly";
import ExceedCreditLimit from "./OverDraft/ExceedCreditLimit";
import HighestCreditLimit from "./OverDraft/HighestCreditLimit";
import HowOftenExceedCredit from "./OverDraft/HowOftenExceedCredit";
import OverLimitCharges from "./OverDraft/OverLimitCharges";
import SufferedFinancialHardship from "./OverDraft/SufferedFinancialHardship";
import ReceiveIncome from "./OverDraft/RecieveIncome";
import MonthlyIncome from "./OverDraft/MonthlyIncome";
import Dependents from "./OverDraft/Dependents";
import WereAlreadyPaying from "./OverDraft/WereAlreadyPaying";
import IncomeAndExpV2 from "./OverDraft/IncomeAndExpV2";
import FurtherBorrowing from "./OverDraft/FurtherBorrowing";
import StruggledRepayments from "./OverDraft/StruggledRepayments";
import MaintainedCreditBalance from "./OverDraft/MaintainedCreditBalance";
import StuckinOverdraft from "./OverDraft/StuckinOverdraft";
import HowStuckAffects from "./OverDraft/HowStuckAffects";
import { wereUalreadyPayingOD } from "../../../../Constants/Questions";

const initialState = {
    openingCreditLimit: "hide",
    everIncreCredLimit: "hide",
    bankActedIrresponsibly: "hide",
    exceedCreditLimit: "hide",
    highestCreditLimit: "hide",
    howOftenExceedCredit: "hide",
    overLimitCharges: "hide",
    sufferedFinancialHardship: "hide",
    recieveAnyIncome: "hide",
    monthlyIncome: "hide",
    dependents: "hide",
    wereAlreadyPaying: "hide",
    incomeAndExpV2: "hide",
    furtherBorrowing: "hide",
    struggledRepayments: "hide",
    maintainedCreditBalance: "hide",
    stuckInOverdraft: "hide",
    howStuckAffects: "hide"
};
const QuestionnaireReducer = (state, action) => {
    switch (action.type) {
        case "showSlideChange": {
            if (action.payload == "openingCreditLimit") {
                return {
                    ...state,
                    openingCreditLimit: "show",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 5,
                };
            } else if (action.payload == "everIncreCredLimit") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "show",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 10,
                };
            } else if (action.payload == "bankActedIrresponsibly") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "show",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 15,
                };
            } else if (action.payload == "exceedCreditLimit") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "show",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 20,
                };
            } else if (action.payload == "highestCreditLimit") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "show",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 25,
                };
            } else if (action.payload == "howOftenExceedCredit") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "show",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 30,
                };
            } else if (action.payload == "overLimitCharges") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "show",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 35,
                };
            } else if (action.payload == "sufferedFinancialHardship") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "show",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 40,
                };
            } else if (action.payload == "recieveAnyIncome") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "show",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 45,
                };
            } else if (action.payload == "monthlyIncome") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "show",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 50,
                };
            } else if (action.payload == "dependents") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "show",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 55,
                };
            } else if (action.payload == "wereAlreadyPaying") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "show",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 60,
                };
            } else if (action.payload == "incomeAndExpV2") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "show",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 65,
                };
            } else if (action.payload == "furtherBorrowing") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "show",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 70,
                };
            } else if (action.payload == "struggledRepayments") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "show",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 75,
                };
            } else if (action.payload == "maintainedCreditBalance") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "show",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "hide",
                    percentage: 80,
                };
            } else if (action.payload == "stuckInOverdraft") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "show",
                    howStuckAffects: "hide",
                    percentage: 90,
                };
            } else if (action.payload == "howStuckAffects") {
                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncreCredLimit: "hide",
                    bankActedIrresponsibly: "hide",
                    exceedCreditLimit: "hide",
                    highestCreditLimit: "hide",
                    howOftenExceedCredit: "hide",
                    overLimitCharges: "hide",
                    sufferedFinancialHardship: "hide",
                    recieveAnyIncome: "hide",
                    monthlyIncome: "hide",
                    dependents: "hide",
                    wereAlreadyPaying: "hide",
                    incomeAndExpV2: "hide",
                    furtherBorrowing: "hide",
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "hide",
                    howStuckAffects: "show",
                    percentage: 95,
                };
            } else {
                return {
                    ...state,
                };
            }
        }
    }
};
const OverDraftQuestionForm = ({
    allFollowupData,
    pendingQuestionnaire,
    splitName,
    currentUuid,
    t2aApiReqData,
    t2aStatus,
    setDivShow,
    setChecklistOptions,
    checklistOptions,
}) => {

    const history = useHistory();
    const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
    const [completedQuestArr, setCompletedQuestArr] = useState([]);
    const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
    const splitForm = useRef(null);
    const { getUserVerfyValidation, saveFollowupUserVerify } = useUserVerify();
    const { updateMilestone } = userMilestoneUpdate();
    const [t2aModal, SetT2aModal] = useState(false);
    const { updateMilestone: updateMilestoneNew } = useUpdateMilestone();
    const flp_source = allFollowupData.atp_sub6;
    const [checkedOptions, setCheckedOptions] = useState([]);

    localStorage.setItem("flp_source", flp_source);

    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    useEffect(() => {
        slideChange(pendingQuestionnaire);
    }, [pendingQuestionnaire]);

    const slideChange = (pendingArr) => {
        let pendingCount = pendingArr.length;
        if (pendingCount == 0) {
            sendT2aApiReqDataAPI();
        }

        pendingArr.every((element) => {
            dispatch({ type: "showSlideChange", payload: element });
        });
    };
    const storeFollowupQuestionnire = async (question_data) => {
        const response = await saveFollowupUserQuestions(
            question_data,
            allFollowupData,
            "followup_question_store"
        );
    };

    const onselectAnswer = async (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        let anslabel = e.target.getAttribute("data-ans-label");
        let flage = false;
        let question_data = {};
        const valuesreg = getValues();
        let cardMonthlyIncome = valuesreg['cardMonthlyIncome'];
        let dependents = valuesreg['dependents'];

        if (e.target.name == "everincresedCredLimit") {
            if (selectedAns && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            removeFromPending("everIncreCredLimit");
            if (selectedAns == 247) {
                removeFromPending("exceedCreditLimit");
            } else if (selectedAns == 248) {
                removeFromPending("bankActedIrresponsibly");
            }
        } else if (e.target.name == "bankActedIrresponsibly") {
            if (selectedAns && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            removeFromPending("bankActedIrresponsibly");
        } else if (e.target.name == "exceedCredLimit") {
            if (selectedAns && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            if (selectedAns == 251) {
                removeFromPending("highestCreditLimit");
            } else if (selectedAns == 252) {
                removeFromPending("highestCreditLimit");
                removeFromPending("howOftenExceedCredit");
                removeFromPending("overLimitCharges");
            }
            removeFromPending("exceedCreditLimit");
        } else if (e.target.name == "oftenexceedCredit") {
            if (selectedAns && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            removeFromPending("howOftenExceedCredit");
        } else if (e.target.name == "overLimitCharge") {
            if (selectedAns && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            removeFromPending("overLimitCharges");
        } else if (e.target.name == "financialhardship") {
            if (selectedAns && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            removeFromPending("sufferedFinancialHardship");
        } else if (e.target.name == "recieveanyincome") {
            if (selectedAns && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            if (selectedAns == 268) {
                removeFromPending("monthlyIncome");
            }
            removeFromPending("recieveAnyIncome");
        } else if (e.target.name == "cardMonthlyIncome") {
            if (selectedQuest && cardMonthlyIncome) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: cardMonthlyIncome,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            addToPending("dependents");
            removeFromPending("monthlyIncome");
        } else if (e.target.name == "dependents") {
            if (dependents && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: dependents,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            removeFromPending("dependents");
        } else if (e.target.name == "wereAlreadyPaying") {
            if (selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: null,
                    answer_text: anslabel,
                    input_answer: checklistOptions,
                };
            }
            removeFromPending("wereAlreadyPaying");
        } else if (e.target.name == "furtherBorrow") {
            if (selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: null,
                    answer_text: anslabel,
                    input_answer: checkedOptions,
                };
            }
            removeFromPending("furtherBorrowing");
        } else if (e.target.name == "struggledRepayments") {
            if (selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            removeFromPending("struggledRepayments");
        } else if (e.target.name == "maintainedCreditBalance") {
            if (selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            removeFromPending("maintainedCreditBalance");
        } else if (e.target.name == "stuckinOverdraft") {
            if (selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
            if (selectedAns == 293) {
                removeFromPending("howStuckAffects");
            }
            removeFromPending("stuckInOverdraft");
        } else {
            if (selectedAns && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
        }

        if (flage) {
            storeFollowupQuestionnire(question_data);
            var existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (existingEntries == null) existingEntries = [];
            localStorage.setItem(
                "currentquestionData",
                JSON.stringify(question_data)
            );
            existingEntries.push(question_data);
            localStorage.setItem("questionData", JSON.stringify(existingEntries));
        }
    };

    const saveInputAns = async (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        setCompletedQuestArr([...completedQuestArr, selectedQuest]);
        const valuesreg = getValues();
        let openingCreditLimit = valuesreg["opncreditLimit"];
        let highestCreditLimit = valuesreg["highcreditLimit"];
        let howStuckAffectsbox = valuesreg["howStuckAffectsbox"];

        let flage = false;
        let question_data = {};

        if (selectedQuest == "91" && openingCreditLimit && e.target.name == "openingCreditLimit") {
            flage = true;
            question_data = {
                question_id: selectedQuest,
                option_id: null,
                answer_text: "",
                input_answer: "£" + openingCreditLimit,
            };
            removeFromPending("openingCreditLimit");
        } else if (selectedQuest == 91 && e.target.name == "dontRemember") {
            flage = true;
            question_data = {
              question_id: selectedQuest,
              option_id: null,
              answer_text: "",
              input_answer: "I Don't Remember",
            };
            removeFromPending("openingCreditLimit");
        } else if (selectedQuest == "94" && highestCreditLimit) {
            flage = true;
            question_data = {
                question_id: selectedQuest,
                option_id: null,
                answer_text: "",
                input_answer: highestCreditLimit,
            };
            addToPending("exceedCreditLimit");
            removeFromPending("highestCreditLimit");
        } else if (selectedQuest == "106" && howStuckAffectsbox) {
            flage = true;
            question_data = {
                question_id: selectedQuest,
                option_id: null,
                answer_text: "",
                input_answer: howStuckAffectsbox.trim(),
            };
            removeFromPending("howStuckAffects");
        }

        if (flage) {
            storeFollowupQuestionnire(question_data);
            var existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (existingEntries == null) existingEntries = [];
            localStorage.setItem(
                "currentquestionData",
                JSON.stringify(question_data)
            );
            existingEntries.push(question_data);
            localStorage.setItem("questionData", JSON.stringify(existingEntries));
        }
    };

    const unQualifiedAnswer = (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        let flag = false;
        let question_data = {};
    
        if (selectedQuest && selectedAns){
          flag = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: "",
            input_answer: null,
          };
       }
    
        if (flag == true) {
            storeFlpUnqualifiedQuestionnire(question_data);
        }
      };
      const storeFlpUnqualifiedQuestionnire = async (question_data) => {
        const response = await saveFollowupUserQuestions(
          question_data,
          allFollowupData,
          "unqualified_question_store_flp"
        );
      };
    

    const saveSlide80 = async (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));

        setCompletedQuestArr([...completedQuestArr, selectedQuest]);

        const values = getValues();
        let slide_array = [];

        if (checklistOptions.some((option) => option.id === 228)) {
            let answer228 = splitForm.current["question_228_229"].value;
            slide_array.push(["228", answer228]);
        }
        if (checklistOptions.some((option) => option.id === 229)) {
            let answer229 = splitForm.current["question_228_229"].value;
            slide_array.push(["229", answer229]);
        }
        if (checklistOptions.some((option) => option.id === 230)) {
            let answer230 = splitForm.current["question_230"].value;
            slide_array.push(["230", answer230]);
        }
        if (checklistOptions.some((option) => option.id === 231)) {
            let answer231 = splitForm.current["question_231"].value;
            slide_array.push(["231", answer231]);
        }
        if (checklistOptions.some((option) => option.id === 232)) {
            let answer232 = splitForm.current["question_232"].value;
            slide_array.push(["232", answer232]);
        }
        if (checklistOptions.some((option) => option.id === 233)) {
            let answer233 = splitForm.current["question_233"].value;
            slide_array.push(["233", answer233]);
        }
        if (checklistOptions.some((option) => option.id === 234)) {
            let answer234 = splitForm.current["question_234"].value;
            slide_array.push(["234", answer234]);
        }
         if (checklistOptions.some((option) => option.id === 296)) {
            let answer296 = splitForm.current["question_296"].value;
            slide_array.push(["296", answer296]);
        }
        if (checklistOptions.some((option) => option.id === 297)) {
            let answer297 = splitForm.current["question_297"].value;
            slide_array.push(["297", answer297]);
        }
        if (checklistOptions.some((option) => option.id === 235)) {
            let answer235 = splitForm.current["question_235"].value;
            slide_array.push(["235", answer235]);
        }
       

        var question_data = {
            slide_80: ["80", JSON.stringify(slide_array)],
        };

        removeFromPending("incomeAndExpV2");
        storeFollowupQuestionnire(question_data);

        let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (_existingEntries == null) _existingEntries = [];
        let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
        if (existingIndex !== -1) {
            _existingEntries.splice(existingIndex, 1);
        }
        _existingEntries.push(question_data);
        localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    };

    const addToPending = (slidename) => {
        let index = pendingQuestionnaire.indexOf(slidename);
        if (index !== -1) {
            pendingQuestionnaire.splice(index, 1);
        }

        pendingQuestionnaire.unshift(slidename);
        slideChange(pendingQuestionnaire);
    };
    const removeFromPending = (slidename) => {
        const index = pendingQuestionnaire.indexOf(slidename);
        if (index > -1) {
            pendingQuestionnaire.splice(index, 1);
        }
        slideChange(pendingQuestionnaire);
    };

    const sendT2aApiReqDataAPI = () => {
        SetT2aModal(true);
        const useruuid = t2aApiReqData.uuid;
        const fName = t2aApiReqData.fName;
        const lName = t2aApiReqData.lName;
        const pCode = t2aApiReqData.pCode;
        const addressLine1 = t2aApiReqData.addressLine1;
        const dobDay = t2aApiReqData.day;
        const dobMonth = t2aApiReqData.month;
        const dobYear = t2aApiReqData.year;
        const addressLine2 = t2aApiReqData.addressLine2;
        const postTown = t2aApiReqData.postTown;
        const country = t2aApiReqData.country;
        const title = t2aApiReqData.title;
        const addressLine3 = t2aApiReqData.addressLine3;
        const addressLine4 = t2aApiReqData.addressLine4;
        const county = t2aApiReqData.county;
        if (
            useruuid &&
            fName &&
            lName &&
            pCode &&
            addressLine1 &&
            t2aStatus == null
        ) {
            (async () => {
                const response = await getUserVerfyValidation(
                    useruuid,
                    fName,
                    lName,
                    pCode,
                    addressLine1,
                    dobYear,
                    dobMonth,
                    dobDay,
                    addressLine2,
                    postTown,
                    country,
                    title,
                    addressLine3,
                    addressLine4,
                    county
                );
                storeT2aResponse(response.data);
                if (response.data.status == "success") {
                    updateMilestoneNew(currentUuid, "kyc_check", flp_source, 1);
                    updateMilestoneNew(currentUuid, "questions", flp_source);
                    SetT2aModal(false);
                    localStorage.setItem("t2a_result", 1);
                    localStorage.setItem("t2aValidation", 1);
                    localStorage.setItem("split_name", splitName);
                    history.push("/view_docs_flp?uuid=" + currentUuid);
                } else {
                    updateMilestoneNew(currentUuid, "kyc_check", flp_source, 2);
                    updateMilestoneNew(currentUuid, "questions", flp_source);
                    SetT2aModal(false);
                    localStorage.setItem("t2a_result", 0);
                    localStorage.setItem("t2aValidation", 0);
                    setDivShow("idUpload");
                }
            })();
        } else {
            localStorage.setItem("t2a_result", t2aStatus);
            SetT2aModal(false);
            let email_skip = localStorage.getItem("email_doc");
            if (useruuid && email_skip != 1) {
                (async () => {
                    const response = await updateMilestone(
                        useruuid,
                        t2aStatus,
                        allFollowupData
                    );
                })();
            }

            if (t2aStatus == 1) {
                updateMilestoneNew(currentUuid, "questions", flp_source);
                history.push("/view_docs_flp?uuid=" + currentUuid);
            } else {
                updateMilestoneNew(currentUuid, "questions", flp_source);
                setDivShow("idUpload");
            }
        }
    };

    const storeT2aResponse = async (t2a_data) => {
        const response = await saveFollowupUserVerify(
            allFollowupData,
            t2a_data,
            "person_verify_store_flp",
            queryString,
            t2aApiReqData
        );
    };

    return (
        <>
            <div className="QuestionnaireV7">
                <div className="formsection">
                    <div className="container">
                        <div className="row">
                            <form
                                ref={splitForm}
                                name="split_form"
                                id="user_form"
                                method="POST"
                                autoComplete="off"
                            >
                                <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.openingCreditLimit}`}
                                        id="breakdown01"
                                    >
                                        <OpeningCreditLimit
                                            validation={register}
                                            getValues={getValues}
                                            trigger={trigger}
                                            saveInputAns={saveInputAns}
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.everIncreCredLimit}`}
                                        id="breakdown02"
                                    >
                                        <EverIncreCredLimit
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.bankActedIrresponsibly}`}
                                        id="breakdown03"
                                    >
                                        <BankActedIrresponsibly
                                            onselectAnswer={onselectAnswer}
                                            unQualifiedAnswer={unQualifiedAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.exceedCreditLimit}`}
                                        id="breakdown04"
                                    >
                                        <ExceedCreditLimit
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.highestCreditLimit}`}
                                        id="breakdown05"
                                    >
                                        <HighestCreditLimit
                                            validation={register}
                                            getValues={getValues}
                                            trigger={trigger}
                                            saveInputAns={saveInputAns}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top2 ${state.howOftenExceedCredit}`}
                                        id="breakdown06"
                                    >
                                        <HowOftenExceedCredit
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top2 ${state.overLimitCharges}`}
                                        id="breakdown07"
                                    >
                                        <OverLimitCharges
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.sufferedFinancialHardship}`}
                                        id="breakdown08"
                                    >
                                        <SufferedFinancialHardship
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.recieveAnyIncome}`}
                                        id="breakdown09"
                                    >
                                        <ReceiveIncome
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.monthlyIncome}`}
                                        id="breakdown10"
                                    >
                                        <MonthlyIncome
                                            onselectAnswer={onselectAnswer}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                            trigger={trigger}
                                            setError={setError}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.dependents}`}
                                        id="breakdown11"
                                    >
                                        <Dependents
                                            onselectAnswer={onselectAnswer}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                            trigger={trigger}
                                            clearErrors={clearErrors}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.wereAlreadyPaying}`}
                                        id="breakdown12"
                                    >
                                        <WereAlreadyPaying
                                            onselectAnswer={onselectAnswer}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                            checklistOptions={checklistOptions}
                                            setChecklistOptions={setChecklistOptions}
                                            questArr={wereUalreadyPayingOD}
                                        />
                                    </div>
                                    <div
                                        className={`question6 animated fadeInUp space-top2 ${state.incomeAndExpV2}`}
                                        id="breakdown13"
                                    >
                                        <IncomeAndExpV2
                                            questArr={wereUalreadyPayingOD}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                            trigger={trigger}
                                            saveSlide80={saveSlide80}
                                            splitForm={splitForm}
                                            checklistOptions={checklistOptions}
                                            setError={setError}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.furtherBorrowing}`}
                                        id="breakdown14"
                                    >
                                        <FurtherBorrowing
                                            setCheckedOptions={setCheckedOptions}
                                            checkedOptions={checkedOptions}
                                            onselectAnswer={onselectAnswer}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.struggledRepayments}`}
                                        id="breakdown15"
                                    >
                                        <StruggledRepayments
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.maintainedCreditBalance}`}
                                        id="breakdown16"
                                    >
                                        <MaintainedCreditBalance
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top2 ${state.stuckInOverdraft}`}
                                        id="breakdown17"
                                    >
                                        <StuckinOverdraft
                                            onselectAnswer={onselectAnswer}
                                        />
                                    </div>
                                    <div
                                        className={`question1 animated fadeInUp space-top ${state.howStuckAffects}`}
                                        id="breakdown18"
                                    >
                                        <HowStuckAffects
                                            saveInputAns={saveInputAns}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                            trigger={trigger}
                                            setError={setError}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <AnalyzeModal modalClass={t2aModal} />
                    </div>
                </div>
            </div>
            <QuestionnaireFooter percentage={state.percentage} />
        </>
    );
};

export default OverDraftQuestionForm;